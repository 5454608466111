import './style.scss'
import {Button, Col, Container, Row} from "react-bootstrap";

import bullArrowUp from "../../assets/images/bull-up.png"
import bg from "../../assets/images/3rd-design/followus-bg.jpg";
import x from "../../assets/images/3rd-design/x.png";
import t from "../../assets/images/3rd-design/t.png";
import y from "../../assets/images/3rd-design/y.png";

function ContactUs() {

    const socials = [
        {
            label: 'Twitter',
            link: 'https://twitter.com/BullRunLife',
            img: x,
        },
        {
            label: 'Telegram',
            link: 'https://t.me/BullRunLife',
            img: t
        },
        {
            label: 'Youtube',
            link: 'https://youtube.com/c/BeCreative2',
            img: y
        }
    ]

    return (
        <Container fluid className={'section-continuous d-flex'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'center',
            backgroundSize: '100% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)'
        }} id={'followus'}>
            <Container>
                <Row>
                    <Col md={12} className={'sectionText text-center mt-5'}>
                        <h1 className={'mt-5'}>FOLLOW US</h1>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={8} className={'contactSocials row px-md-5 mx-0'}>
                        {socials.map((s) => {
                            return <Col md={4} className={'text-center'}><a key={`contactSocial${s.label}`}
                                                                            className={'mb-3'} href={s.link}
                                                                            rel="noreferrer" target={'_blank'}><img
                                src={s.img} width={100}/> </a></Col>
                        })}
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={1}></Col>
                    <Col md={7} className={'row text-white mt-5'}>
                        <Col md={3} style={{fontSize: 20}}>
                            Contact:<br/> <b><a style={{color: '#FFF'}} href={'mailto:info@bullrun.life'}>info@bullrun.life</a></b>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ContactUs;
