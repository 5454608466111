import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/liquidity-single-bg.jpg";
import bull from "../../assets/images/3rd-design/bull-with-helmet.png";

function Liquidity() {

    return (
        <Container fluid className={'section-continuous d-flex justify-content-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'center',
            backgroundSize: '100% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            position: 'relative'
        }}>
            <Container>

                <Row className={'mt-5'}>

                    <Col md={12} className={'text-start'}>
                        <div className={'sectionText mt-2'}>
                            <h2 className={'text-primary text-start'}>BULLRUN</h2>
                            <h3 className={'text-white text-start'}>LIQUIDITY</h3>
                        </div>
                    </Col>
                    <Col md={4} className={'sectionText'}>
                        <p className={'normal text-white withShadow'}>
                        Tired of being the Exit liquidity? Good!
                            <br/>The BULLRUN ecosystem does the work for you to accumulate and retain the exit liquidity with every trade. It does so through these three mechanisms:
                        <br/>
                            1.	Burning BRL tokens removes them from the total supply.<br/>
                        2.	USDC distributions to Staking participants.<br/>
                        3.	Retention of liquidity with every trade<br/>
                        </p>
                    </Col>
                    <Col md={3}></Col>
                    <Col md={5} className={'sectionText'}>
                        <p className={'moreNormal text-white withShadow'}>
                            <b style={{fontWeight: 900}}>WE ARE THE BULLDOZERS!<br/>
                                WE TRIGGER OUR OWN BULLRUN AT WILL!</b>
                        </p>
                        <p className={'moreNormal text-white withShadow'}>
                            Liquidity is the engine behind any ecosystem and is the element that dictates boom and bust cycles or better known as bull markets or bear markets.
                        </p>
                        <p className={'moreNormal text-primary withShadow'}>
                            We have developed an ecosystem 	that
                            organically retains and sustains liquidity in
                            a way that is scalable and self-sufficient.
                        </p>
                    </Col>
                    <img src={bull} className={'rightBottomImg'}/>
                </Row>
            </Container>
        </Container>
    )
}

export default Liquidity;
