import "./style.scss";

import {Button} from "react-bootstrap";
import {shorter} from "../../utils/string";
import {useEthers} from "@usedapp/core";
import config from "../../config";



function DisconnectBtn({account}) {

    const {deactivate} = useEthers();

    return (
        <Button className={'disconnectBtn'} onClick={() => {
            localStorage.removeItem(config.constants.STORAGE.AUTH_TOKEN);
            localStorage.removeItem(config.constants.STORAGE.CONNECTOR);
            deactivate();
        }}>DISCONNECT {shorter(account)}</Button>
    )
}

export default DisconnectBtn;