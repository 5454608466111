import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/buybullrun-bg.jpg";
import metamask from "../../assets/images/3rd-design/metamask.png";
import uniswap from "../../assets/images/3rd-design/uniswap.png";
import buyButton from "../../assets/images/3rd-design/buy-bullrun.png";
import metmaskphone from "../../assets/images/3rd-design/metamask-phone.png";
import bull from "../../assets/images/3rd-design/bull-with-helmet.png";

import {useSystem} from "../../hooks/use-system";
import addresses from "../../config/addresses";

function BuyBullRun() {

    const system = useSystem();

    return (
        <Container fluid className={'section-continuous align-items-center justify-content-center'} id={"utilities"}
                   style={{
                       backgroundImage: `url(${bg})`,
                       backgroundPosition: 'center center',
                       backgroundSize: 'cover',
                       backgroundColor: 'rgba(0,0,0, 0.2)',
                       position: 'relative'
                   }}>
            <Container className={'pt-5'}>
                <Row className={'mt-5 mb-md-0'}>
                    <Col xs={12} className={'sectionText text-start mb-2'}>
                        <h2 className={'text-primary'}>BULLRUN</h2>
                        <h3 className={'text-white'}>HOW TO BUY BULLRUN</h3>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'p-0 m-0'}>
                <Row className={'p-0 m-0'} style={{zIndex: 10, position: 'relative'}}>
                    <Col xs={10} className={'p-0 m-0'}>
                        <div className={'bg-opacity-75 bg-dark text-start text-white buyBullRunHeader p-3'}>
                            <img src={metmaskphone} className={'metamask'}/>

                            <Container>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={2} className={'text-end'}><img src={metamask}/> </Col>
                                    <Col>Download Metamask or your wallet of choice from the app store or google play
                                        store for free. Desktop users, download the google chrome extension by going to
                                        metamask.io. </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
                <Row className={'p-0 m-0'} tyle={{zIndex: 9, position: 'relative'}}>
                    <Col xs={10} className={'p-0 m-0'} style={{position: 'relative'}}>
                        <div className={'bg-primary text-start fw-bold buyBullRunHeader p-3'}>
                            <Container>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={2} className={'text-center'}></Col>
                                    <Col>Make sure you have ETH in your wallet to swap to, fI
                                        you don't have any ETH, you can buy directly on metamask, transfer from another wallet, or
                                        buy on another exchange and send ti to your wallet.</Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
                <Row className={'p-0 m-0'} style={{position: 'relative', zIndex: 9}}>
                    <Col xs={10} className={'p-0 m-0'}>
                        <div className={'bg-opacity-50 bg-dark text-start text-white p-3 buyBullRunHeader'}>
                            <img src={bull} className={'bullRight'}/>
                            <Container>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={2} className={'text-end'}><img src={uniswap}/> </Col>
                                    <Col> Connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside
                                        your Metamask app. Connect your wallet. Paste the BULLRUN token address into Uniswap, select
                                        BULLRUN, and confirm. When Metamask prompts you for a wallet signature, sign.</Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
                <Row className={'p-0 m-0'}>
                    <Col xs={10} className={'p-0 m-0'} style={{position: 'relative'}}>
                        <div className={'bg-primary text-start buyBullRunHeader p-3'}>

                            <Container>
                                <Row className={'d-flex align-items-center'}>
                                    <Col xs={2} className={'text-center'}></Col>
                                    <Col><b style={{textTransform: 'uppercase'}}> Swap ETH for BULLRUN. We have 6% taxes, so be sure to
                                        place your slippage to 7%</b> </Col>
                                </Row>
                            </Container>

                        </div>
                    </Col>
                </Row>
                <Row className={'p-0 m-0 mb-auto'}>
                    <Col xs={12} className={'p-0 my-5 text-center'} style={{position: 'relative'}}>
                        <img src={buyButton} className={'mx-auto animate-beats '} style={{cursor: 'pointer'}}
                             onClick={() => {
                                 window.open(`https://app.uniswap.org/#/swap?outputCurrency=${addresses.brl}`)
                             }}/>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default BuyBullRun;
