import config from "../config";

const baseURL = process.env.REACT_APP_ENV === 'production'
    ? 'https://apis.bullrun.life/api' : (
        process.env.REACT_APP_ENV === 'staging'
            ? 'https://dev-apis.bullrun.life/api' : 'http://localhost:8000/api'
    );


const actions = {
    activateDevice: data => ({
        method: 'POST',
        url: '/auth/activate-device'
    }),
    getTwitterLoginLink: data => ({
        method: 'GET',
        url: '/launchpad/twitter/get-link'
    }),
    validateTwitter: data => ({
        method: 'POST',
        url: '/launchpad/twitter/validate'
    }),
    collectionInfo: data => ({
        method: 'GET',
        url: '/launchpad/collection/info'
    }),
    collections: data => ({
        method: 'GET',
        url: '/launchpad/collections'
    }),
    validatePayment: data => ({
        method: 'POST',
        url: '/launchpad/validate'
    })
}

const callAPI =
    (data, type) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth-token", localStorage.getItem(config.constants.STORAGE.AUTH_TOKEN));
        myHeaders.append("device-id", localStorage.getItem(config.constants.STORAGE.DEVICE_ID));

        let dt = actions[type](data);

        var requestOptions = {
            method: dt.method,
            headers: myHeaders,
            redirect: 'follow'
        };

        if (dt.method.toUpperCase() === 'POST') {
            requestOptions.body = JSON.stringify(data);
        } else if(dt.method.toUpperCase() === 'GET'){
           if(data){
               let query = Object.keys(data)
                   .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
                   .join('&');
               dt.url += "?"+query
           }
        }

        return fetch(baseURL + dt.url, requestOptions)
            .then(resp => resp.json())
            .then(function (resp) {
                return resp;
            })
            .catch(function (error) {
                console.error(error);
                return {};
            });
    };

export default callAPI;