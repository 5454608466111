import "./style.scss"

import {Container} from "react-bootstrap";
import Header from "../../sections/Header";
import Hero from "../../sections/Hero";
import WhyBullRun from "../../sections/WhyBullRun";
import Utilities from "../../sections/Utilities";
import Tokenomics from "../../sections/Tokenomics";
import Team from "../../sections/Team";
import RoadMap from "../../sections/RoadMap";
import ContactUs from "../../sections/ContactUs";
import TokenValueSupport from "../../sections/TokenValueSupport";
import FAQ from "../../sections/FAQ";
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "../../sections/Footer";
import Disclaimer from "../../sections/Disclaimer";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import AboutBullRun from "../../sections/AboutBullRun";
import ExtraSections from "../../sections/ExtraSections";
import FactsCheck from "../../sections/FactsCheck";
import LiquidityChallenge from "../../sections/LiquidityChallenge";
import BoosterNFT from "../../sections/BoosterNFT";
import Abstract from "../../sections/Abstract";
import BullRunMission from "../../sections/BullRunMission";
import LiquidityKing from "../../sections/LiquidityKing";
import Liquidity from "../../sections/Liquidity";
import Education from "../../sections/Education";
import Security from "../../sections/Security";
import Social from "../../sections/Social";
import BuyBullRun from "../../sections/BuyBullRun";


function Home({account}) {

    let navigate = useNavigate();
    const search = window.location.search;
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#launchpad') {
            navigate(`/launchpad`);
        }
    })

    return (
        <Container fluid className={'align-content-center vh-100 page-container'} style={{backgroundColor: 'black'}}>
            <div style={{
                width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0, 0.7)', position: 'fixed', top: 0, left: 0
            }}></div>
            <Header account={account}/>
            <ScrollToTop/>
            <Hero/>
            <AboutBullRun/>
            <BullRunMission/>
            <LiquidityKing/>
            <Liquidity/>
            <Education/>
            <Security/>
            <Social/>
            <Utilities/>
            <BuyBullRun/>
            <BoosterNFT />
            <FactsCheck />
            <Tokenomics/>
            <TokenValueSupport/>
            <RoadMap/>
            <ContactUs/>
            <Disclaimer
                text={`The only function of the BULLRUN token, or BULLRUN NFTs, is to  grant holders access to all future tokens and NFT collections Holding BRL should not be considered an investment. No statements made in this document, nor any BULLRUN communication channels, nor by any BULLRUN “community members” or holders, should be considered financial advice. BRL holders should not expect any “return on investment”, nor that the “BULLRUN team” will work to provide value to their holdings.`}/>
            <Footer/>
        </Container>
    )

}

export default Home;
