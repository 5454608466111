import './App.scss';

import {Routes, Route, BrowserRouter, Navigate, useSearchParams, useLocation, useNavigate} from "react-router-dom";
import Home from "./pages/Home";
import {useEthers} from "@usedapp/core";
import config from "./config";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import NFTLaunchPad from "./pages/NFTLaunchPad";
import {isAndroid, isIOS, isMacOs, isWindows} from "react-device-detect";
import callAPI from "./api";
import browserSignature from 'browser-signature';
import TagManager from 'react-gtm-module';
import MadzTerms from "./pages/NFTLaunchPad/Madz/MadzTerms";
import Dashboard from "./pages/Dashboard";

const tagManagerArgs = {
    gtmId: 'G-TXT62FF30E'
}

TagManager.initialize(tagManagerArgs)

function App() {

    const [signed, setSigned] = useState(false);
    const {account, activate, chainId, library, deactivate} = useEthers();
    const envChainId = config.chainId;


    const requestSignature = async (callBack) => {
        const d = new Date();
        const messageToSign = "Welcome to BullRun!\n\nClick the 'Sign' button to login on BullRun.life on" + d.toLocaleString() + " \n\nThis request will not trigger a blockchain transaction or cost any gas fees";

        const signature = await library.getSigner().signMessage(messageToSign,);

        activateDevice(messageToSign, signature)
    }

    const activateDevice = (message, signature) => {
        const browser_id = browserSignature();
        localStorage.setItem(config.constants.STORAGE.DEVICE_ID, browser_id);

        callAPI({
            message: message,
            signature: signature,
            wallet: account,
            device_id: browser_id,
            device_type: isAndroid ? 'ANDROID' : (isIOS ? 'IOS' : (isWindows ? 'Windows' : (isMacOs ? 'MAC' : 'UNKNOWN')))
        }, 'activateDevice').then((resp) => {
            if (resp.status) {
                localStorage.setItem(config.constants.STORAGE.AUTH_TOKEN, resp.payload.auth_token);
                localStorage.setItem(config.constants.STORAGE.WALLET, account);
                setSigned(true);
            } else{
                setSigned(false);
                deactivate();
            }

        }).catch((err) => {
            setSigned(false);
            deactivate();
        });
    }

    useEffect(() => {
        const authTokenSaved = localStorage[config.constants.STORAGE.AUTH_TOKEN];
        const walletSaved = localStorage[config.constants.STORAGE.WALLET];

        const shouldSign = (account && !authTokenSaved && !signed) || (account && authTokenSaved && account != walletSaved)
        console.log('Should Sign: %s, Signed: %s', shouldSign, signed);
        if (shouldSign) {
            console.log('Requesting Signature');
            requestSignature();
        }

        if(!account){
            setSigned(false);
            if(localStorage[config.constants.STORAGE.AUTH_TOKEN] && localStorage[config.constants.STORAGE.CONNECTOR] == 'Injected'){
                activate(config.Connectors.injected);
                setSigned(true);
            }
        }

    }, [account]);

    useEffect(() => {
        if (account && chainId && window.ethereum) {
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: '0x' + envChainId}],
            });
        }
    }, [chainId, envChainId]);


    const search = window.location.search;

    return (
        <div className="App">
            <header className="App-header">

            </header>
            <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>BULL RUN | HYPER DEFLATIONARY TOKENS</title>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:site" content="@BullRunLife"/>
                    <meta name="twitter:url" content={window.location.href}/>
                    <meta name="twitter:title" content="BULL RUN | HYPER DEFLATIONARY TOKENS"/>
                    <meta name="twitter:description" content=""/>
                    <meta name="twitter:image"
                          content={`${window.location.protocol}//${window.location.host}/bullrun_share.jpg`}/>
                    <meta name="twitter:image:alt" content="BULL RUN | HYPER DEFLATIONARY TOKENS"/>
                    <meta property="og:title" content="BULL RUN | HYPER DEFLATIONARY TOKENS"/>
                    <meta property="og:description" content=""/>
                    <meta property="og:image"
                          content={`${window.location.protocol}//${window.location.host}/bullrun_share.jpg`}/>
                    <meta property="og:image:secure_url"
                          content={`${window.location.protocol}//${window.location.host}/bullrun_share.jpg`}/>
                    <meta property="og:url" content={window.location.href}/>
                    <meta name="description" content="BULL RUN | HYPER DEFLATIONARY TOKENS"/>
                    <meta property="og:type" content="website"/>
                    <meta name="author" content=""/>
                </Helmet>
                <Routes>
                    <Route path={'/launchpad'} element={<NFTLaunchPad account={account} signed={signed} />} key={'NFT Launchpad'}/>
                    <Route path={'/launchpad/:projectSlug'} element={<NFTLaunchPad account={account} signed={signed}/>} key={'NFT Launchpad'}/>
                    <Route path={'/launchpad/madz/terms'} element={<MadzTerms />} key={'Madz Terms'}/>
                    <Route path={'/dashboard'} element={<Dashboard chainId={chainId} account={account} />} key={'Dashboard'}/>
                    <Route path={'*'} element={<Home account={account}/>} key={'Home'}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
