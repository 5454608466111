const stage =
    process.env.REACT_APP_ENV === 'production'
        ? 'MAINNET' : (
            process.env.REACT_APP_ENV === 'staging'
                ? 'GOERLI' : process.env.REACT_APP_ENV.toUpperCase()
        );
export default {
    brl: process.env[`REACT_APP_TOKEN_ADDRESS_${stage}`],
    staking: process.env[`REACT_APP_STAKING_ADDRESS_${stage}`],
    stakingOld: process.env[`REACT_APP_OLD_STAKING_ADDRESS_${stage}`],
    nft: process.env[`REACT_APP_STAKING_NFT_ADDRESS_${stage}`],
    usdc: process.env[`REACT_APP_USDC_ADDRESS_${stage}`],
    link: "0x514910771af9ca656af840dff83e8264ecf986ca",
    usdt: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    shib: "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
    matic: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
    cro: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
    mana: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
    volt: '0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac',
    neuroni: '0x922e2708462c7a3d014d8344f7c4d92b27ecf332',
    ldo: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    ape: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    sand: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
    aave: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    uni: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984'
};