import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/utilities-bg.jpg";
import sideBull from "../../assets/images/3rd-design/side_bull.png";
import sideBullLeft from "../../assets/images/3rd-design/side_bull_left.png";
import stakeButton from "../../assets/images/3rd-design/stake-button.png";

import {useSystem} from "../../hooks/use-system";

function Utilities() {

    const system = useSystem();

    const utilities = [
        // {
        //     title: 'DUAL STAKING',
        //     description: 'BRL Dual Staking Plartform allows you to stake both Tokens and your Booster NFT. By Staking only your BRL Tokens without the BULLRUN BOOSTERS NFT, you are limited to only 70% USDC distributions. However , with every additional BULLRUN NFT BOOSTER you recieve 6% for up to a maximum of the five BOOSTERS in total. By Stakıng five BOOSTERS , you will unlock 100% USDC distributions from the total Staking Pool.'
        // },
        {
            title: 'TRUE BURN',
            description: 'The True Burn feature burns tokens, with every buy and sell, from the total supply. Scarcity in token supply causes hyper deflation.<Br/><Br/>'
        },
        {
            title: 'BULLDOZER',
            description: 'BULLDOZER is a unique and effective feature that tackles liquidity and volume challenges. Its sole purpose is to generate organic trading volume, provide exit-liquidity and bring sustainability into the BULLRUN ecosystem.'
        },
        {
            title: 'BULLRUN BOOSTER NFT',
            description: 'The BOOSTERS NFT also serve as  \n' +
                'scalable revenue , which are used to feed into the LIQUIDITY Pool, this enables BULLRUN to build and Sustain a Healthy \n' +
                'Liquidity. Volume dictates the USDC distribution amounts.'
        }
    ]

    return (
        <Container fluid className={'section-continuous align-items-center justify-content-center'} id={"utilities"} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top',
            backgroundSize: '100% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            position: 'relative',
            overflow: 'hidden',
            padding: 0,
        }}>
            <Container className={'pt-5'}>
                <Row className={'mt-5 mb-md-0'}>
                    <Col xs={12} className={'sectionText text-start mb-2'}>
                        <h2 className={'text-primary'}>BULLRUN</h2>
                        <h3 className={'text-white'}>UTILITIES</h3>
                    </Col>
                </Row>
                <Row>
                    {utilities.map((u, i) => {
                        return <Col md={4} className={'mb-md-0'} key={`${i} ${u.title}`}>
                            <div className={'utility'}>
                                <div className={'utilityTitle mb-2 p-2'}>{u.title}</div>
                                <div className={'utilityDescription p-2'}
                                     dangerouslySetInnerHTML={{__html: u.description}}></div>
                            </div>
                        </Col>
                    })}
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={'my-2 bg-primary text-center utilityPrimaryHeader'}>Dual Staking</div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className={'mb-3 mb-md-0'}>
                        <div className={'utility'}>
                            <div className={'utilityDescription p-2 transparent'}>
                                BRL Dual Staking Plartform allows you to stake both Tokens and your Booster NFT.
                                By Staking only your BRL Tokens without the BULLRUN BOOSTERS NFT, you are limited to
                                only 70% USDC distributions.
                                However , with every additional BULLRUN NFT BOOSTER you recieve 6% for up to a maximum
                                of the five BOOSTERS in total.
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className={'mb-3 mb-md-0'}>
                        <div className={'utility'}>
                            <div className={'utilityDescription p-2 transparent'}>
                                By Stakıng five BOOSTERS you will unlock 100% USDC
                                distributions from the total Staking Pool.
                                STAKE your BRL Tokens and receive USDC distributions.
                                These are based on the volume and are dynamic, depending
                                on the market conditions.
                                <br/><br/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'p-0 m-0'}>
                <Row className={'p-0 m-0'}>
                    <Col xs={12} className={'p-0 m-0'} >
                        <div className={'p-o m-0 bg-primary text-center utilityPrimaryHeader'}>No staking or unstaking fees | No
                            timelock period
                        </div>
                    </Col>
                </Row>
                <Row className={'p-0 m-0'}>
                    <Col xs={12} className={'p-0 m-0'} style={{position: 'relative'}}>
                        <div className={'text-center utilityGreenHeader'}>BULLRUN STAKING BOOSTER NFT’s boost your distributions 6% each and you can stake a maximum of five
                        </div>
                    </Col>
                </Row>
                <Row className={'p-0 m-0'}>
                    <Col xs={12} className={'p-0 my-5 text-center'} style={{position: 'relative'}}>
                        <img src={stakeButton} className={'mx-auto animate-beats '} style={{cursor: 'pointer'}} onClick={() => {
                            system.showModal('staking')
                        }}/>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Utilities;
