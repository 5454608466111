const CONSTANTS = {
    STORAGE: {
        WALLET: 'wallet',
        AUTH_TOKEN: 'auth_token',
        DEVICE_ID: 'device_id',
        WALLET_TYPE: 'wallet_type',
        CONNECTOR: 'connector'
    },
    CONTRACT_TYPE: {
        ERC721: 'ERC721',
        ERC1155: 'ERC1155'
    }
}

export default CONSTANTS;