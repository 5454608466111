import React, {useEffect, useState} from 'react';
import './style.scss'
import Modal from "../modal";
import {ChainId, useEtherBalance, useEthers, useGasPrice} from "@usedapp/core";

import {
    BalanceOf,
    UseContractMethod, LastRewardTS, NFTBalanceOf, NFTApproved, USDCPerSecond, NFTPrice
} from "../../../hooks/use-dapp";
import {useContract} from "../../../hooks/use-contract";
import {prettyNumber, shorter} from "../../../utils/string";
import {Button, Col, Container, InputGroup, Row, Form, Tabs, Tab} from "react-bootstrap";

import web3 from "web3";
import addresses from "../../../config/addresses";
import {useSystem} from "../../../hooks/use-system";
import config from "../../../config";
import stakingBoosterImg from "../../../assets/images/launchpad/staking_booster.jpg";
import bullUp from "../../../assets/images/bull-up-light.png";


function BoosterNFT({...props}) {

    const system = useSystem();
    const {account, chainId} = useEthers();
    const contract = useContract();

    const [loading, isLoading] = useState(false)
    const [mintQuantity, setMintQuantity] = useState(0);
    const [trxMessage, setTrxMessage] = useState("");


    const nftBalance = NFTBalanceOf(contract?.nft, account, "0");
    const etherBalance = useEtherBalance(account);

    const nftPrice = NFTPrice();

    const {
        state,
        send,
        events
    } = UseContractMethod(contract?.nft, "purchase");

    useEffect(() => {
        if (!account) {
            system.showModal('choose-wallet');
        }
    }, [account])

    useEffect(() => {

        if (state?.status === 'Exception') {
            setTrxMessage(state.errorMessage);
            isLoading(false);
        } else if (state?.status === 'Mining') {

            setTrxMessage(`🔐 Transaction Signed! ${loader()} Minting your NFTs...`);
        } else if (state?.status === 'Success') {
            setTrxMessage(`✅ You have minted successfully ${mintQuantity} NFTs!`);
            setMintQuantity(0);
            isLoading(false);
        }

    }, [state, events])

    const loader = () => {
        return `<div class="spinner-border spinner-border-sm text-light"><span
            class="visually-hidden">Loading...</span></div>`;
    }


    return (
        <Modal {...props} title="BOOSTER NFT" >
            <div className={'text-center wide'}>
                <Container>
                    <Row>
                        <Col md={6} className={'d-flex flex-column justify-content-around'}>
                            <div className={'tokenBalances d-flex align-items-center justify-content-between'}>
                                <div className={'balance general text-md-start text-center'}>
                                    <h5 className={'mb-3'}>Wallet Balance</h5>
                                    <div className={'d-flex align-items-baseline justify-content-between'}>
                                        <h4><small>{nftBalance} NFTs</small></h4>
                                        <h4><small>{prettyNumber(etherBalance?.toBigInt().toString())} ETH</small></h4>
                                    </div>
                                </div>
                            </div>
                            <div className={'text-start'}>
                                How many you wish to mint?
                                <InputGroup className={'mintQuantityController my-2'}>
                                    <InputGroup.Text className={'minQuantityControl'} onClick={() => {
                                        if (!loading)
                                            setMintQuantity(mintQuantity > 0 ? mintQuantity - 1 : 0)
                                    }}>-</InputGroup.Text>
                                    <Form.Control disabled={loading}
                                                  value={mintQuantity}
                                                  type={'number'}
                                                  onChange={e => setMintQuantity(parseInt(e.target.value != "" ? e.target.value : 0))}
                                    />
                                    <InputGroup.Text className={'minQuantityControl'} onClick={() => {
                                        if (!loading)
                                            setMintQuantity(mintQuantity + 1)
                                    }}>+</InputGroup.Text>
                                </InputGroup>
                                <div className={'mt-4'}>
                                    <div className={'d-flex align-items-center justify-content-between'}><h6>NFT Price</h6><span>{prettyNumber(nftPrice)} ETH</span></div>
                                    <div className={'d-flex align-items-center justify-content-between'}><h6>Total</h6><span><b>{(prettyNumber(nftPrice)*mintQuantity).toFixed(1)} ETH</b></span></div>
                                </div>
                                <Button
                                    disabled={loading || mintQuantity == 0 || etherBalance < nftPrice * mintQuantity}
                                    className={'brandButton mt-2'}
                                    onClick={() => {
                                        isLoading(true);
                                        setTrxMessage(`${loader()} Please sign your transaction`)
                                        send(mintQuantity, false, {
                                            value: (nftPrice * mintQuantity).toString()

                                        })
                                    }}>
                                    MINT <img src={bullUp} alt={'BullRun Up Arrow'}/>
                                </Button>
                                <div dangerouslySetInnerHTML={{__html: trxMessage}}></div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <img src={stakingBoosterImg} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </Modal>
    );
}

export default BoosterNFT;
