import './style.scss'
import {Col, Container, Row} from "react-bootstrap";
import bg from "../../assets/images/3rd-design/fact-check-bg.jpg";
import greenBull from "../../assets/images/3rd-design/green_bull.png";

function FactsCheck() {

    return (
        <Container fluid className={'d-flex section-continuous align-items-center justify-content-center'} id={"roadmap"}
                   style={{
                       backgroundImage: `url(${bg})`,
                       backgroundPosition: 'top',
                       backgroundSize: '100% auto',
                       backgroundColor: 'rgba(0,0,0, 0.2)',
                       position: 'relative'
                   }}
                   id={"FactsCheck"}>
            <Container>
                <Row>
                    <Col xs={12} className={'sectionText text-center'}>
                        <h1 className={'text-primary'}>BULLRUN</h1>
                        <h3 className={'text-white'}>FACTS CHECK</h3>
                    </Col>
                </Row>
                <Row className={'factsCheckRow py-4 mx-5'}>
                    <img src={greenBull} style={{
                        width: 250,
                        position: "absolute",
                        left: -130
                    }} />
                    <Col md={5} className={'sectionText text-end'}>
                        <p>
                            Launched with Utility, <span>check!</span><br/>
                            AUDIT completed, <span>check!</span><br/>
                            Mission & Purpose, <span>check!</span><br/>
                            Liquidity accumulation, <span>check!</span><br/>
                            Burns, <span>check!</span><br/>
                            Sustainable, <span>check!</span><br/>
                            LIQUIDITY IS FOR THE PEOPLE, <span>check!</span><br/>
                            No presale, <span>check!</span><br/>
                        </p>
                    </Col>
                    <Col md={1} className={'rightBorderGold'}></Col>
                    <Col md={1}></Col>
                    <Col md={5} className={'sectionText'}>
                        <p>
                            Stealth launch, <span>check!</span><br/>
                            LP tokens LOCKED for 10Years, <span>check!</span><br/>
                            Community driven, <span>check!</span><br/>
                            Zero tax and zero liquidity retention, <span>NO!</span><br/>
                            MEV bots, <span>NO!</span><br/>
                            Sandwich attacks, <span>NO!</span><br/>
                            Liquidity drained, <span>NO!</span><br/>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default FactsCheck;

