import React, {useState} from 'react';
import {PieChart} from 'react-minimal-pie-chart';

function FullOption({chartData, onMouseOver}) {
    const [selected, setSelected] = useState(0)

    const data = chartData.map((entry, i) => {
        return entry;
    });

    return (
        <PieChart
            style={{
                fontFamily:
                    '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                fontSize: '8px'
            }}
            data={data}
            // radius={180- 6}
            radius={50-4}
            lineWidth={50}
            segmentsStyle={{transition: 'all .4s', cursor: 'pointer'}}
            segmentsShift={(index) => (index === selected ? 4: 0)}
            animate
            // label={({dataEntry}) => Math.round(dataEntry.percentage) + '%'}
            // labelPosition={100 - lineWidth / 2}
            labelStyle={{
                fill: '#fff',
                opacity: 0.75,
                pointerEvents: 'none',
                transition: 'all .4s'
            }}
            onMouseOut={(_, index) => {
                setSelected(undefined);
                onMouseOver(undefined);
            }}
            onMouseOver={(_, index) => {
                setSelected(index === selected ? undefined : index);
                onMouseOver(index);
            }}
        />
    );
}

export default FullOption;