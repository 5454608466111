import { ChainId } from '@usedapp/core';

import DappConfig from './dapp';
import Connectors from "./connectors";
import CONSTANTS from "./constants";

const stage = process.env.REACT_APP_ENV;


const config = {
  WS_BASE_URL: process.env.WS_BASE_URL || '',
  Ethscan: (stage === 'staging' ? 'goerli.' : '') + 'etherscan.io',
  Opensea: (stage === 'staging' ? 'testnets.' : ''),
  network: (stage === 'staging' ? 'goerli' : 'mainnet'),
  supported_chain_ids: (stage === 'staging' ? [ChainId.Goerli] : [ChainId.Mainnet]),
  chainId: (stage === 'staging' ? ChainId.Goerli : ChainId.Mainnet),
  networks: {
    MAINNET: ChainId.Mainnet,
    GOERLI: ChainId.Goerli
  },
  networkName: (stage === 'staging' ? 'goerli.' : 'mainnet'),
  Connectors: {...Connectors},
  constants: {...CONSTANTS},
  DappConfig: {...DappConfig},
};
export default config;