import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/liquidity-bg.jpg";

function BullRunMission() {

    return (
        <Container fluid className={'section-continuous d-flex justify-content-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'center',
            backgroundSize: '100% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)'
        }}>
            <Container>
                <Row className={'mt-5'}>
                    <Col md={12}>
                        <div className={'sectionText mt-2'}>
                            <h2 className={'text-center'}>LIQUIDITY KING</h2>
                        </div>
                        <p className={'moreNormal text-white withShadow mt-0 py-3 px-0'} style={{backgroundColor: 'rgba(0,0,0,0.2)'}}>
                            <b>
                            Liquidity is a scares commodity to come by these days. It is the bloodline for an ecosystem to be sustainable and for traders to enter and exit their positions. The HODLers also enjoy USDC distributions, which come from the trade volume. A win-win for everyone!
                            BULLRUN’s ecosystem is built with the community’s best interest in mind. It’s time that an ecosystem works for you and not against you.
                            </b>
                            </p>
                    </Col>
                    {/*<Col md={5} className={'sectionText'} style={{*/}
                    {/*    marginTop: '5%'*/}
                    {/*}}>*/}
                    {/*    <p className={'moreNormal text-white withShadow'}>*/}
                    {/*        BULLRUN aims to be the <span className={'text-primary'}>most LIQUIDITY*/}
                    {/*        UTILITY TOKEN</span>. It has built-in mechanisms within its ecosystem to retain liquidity.*/}
                    {/*        It is time for BULLRUN to reign supreme, as <span className={'text-primary'}>the undisputed LIQUIDITY KING</span>.*/}
                    {/*    </p>*/}
                    {/*</Col>*/}
                    {/*<Col md={2}></Col>*/}
                    {/*<Col md={5} className={'sectionText'}style={{*/}
                    {/*    marginTop: '5%'*/}
                    {/*}}>*/}
                    {/*    <p className={'moreNormal text-white withShadow'}>*/}
                    {/*        WE ARE THE BULLDOZERS!*/}
                    {/*        WE TRIGGER OUR OWN BULLRUN AT WILL!*/}
                    {/*    </p>*/}
                    {/*</Col>*/}
                </Row>
            </Container>
        </Container>
    )
}

export default BullRunMission;
