import './style.scss'
import {Col, Container, Row} from "react-bootstrap";
import PieChart from "../../components/PieChart";

import threeArrows from "../../assets/images/3arrows.png"
import bg from "../../assets/images/3rd-design/tokenomics-bg.jpg";
import {useState} from "react";

function Tokenomics() {

    const [active, setActive] = useState(undefined)

    const distribution = [
        {title: 'BRL / Staking & Buybacks', value: 10, color: '#c09f2e'},
        {title: 'Team', value: 5, color: '#b7980a'},
        {title: 'LP', value: 66, color: '#c9a80a'},
        {title: 'Air drop to V1 holders', value: 19, color: '#967c07'},
    ]

    return (
        <Container fluid className={'d-flex section-continuous align-items-center justify-content-center'} id={"utilities"} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top',
            backgroundSize: '100% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            position: 'relative',
            overflow: 'hidden'
        }} id={"tokenomics"}>
            <Row>
                <Col xs={12} className={'sectionText text-center mb-5'}>
                    <h1>BULLRUN</h1>
                    <h3 className={'text-white'}>TOKENOMICS</h3>
                </Col>
                <Col md={5} className={'pieChart mb-3'} style={{maxHeight: 350}}>
                    <PieChart chartData={distribution} onMouseOver={setActive}/>
                </Col>
                <Col md={7} className={'tokenDistribution text-start px-0'}>
                    <h2 className={'text-primary text-start'}>TOKEN DISTRIBUTION</h2>
                    <div className={'text-start mx-1'}>
                        <p>TOTAL TOKEN SUPPLY - 1,000,000</p>
                        <ul className={"ps-5 ps-md-0"}>
                            {distribution.map((d, i) => {
                                return <li key={`${i}${d.title}`} className={i === active ? 'active' : ''}>{d.value}% {d.title}</li>
                            })}
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Tokenomics;

