import React from "react";
import {useSystem} from "../../hooks/use-system";
import ChooseWallet from "./chooose-wallet";
import ModalContainer from "./modal";
import ConnectingWallet from "./connecting";
import Staking from "./staking";
import NoProvider from "./noprovider";
import BoosterNFT from "./BoosterNFT";
import Calculator from "./Calculator";

const SystemModals = () => {
    const {modal} = useSystem();

    if (modal.name === 'choose-wallet') {
        return <ChooseWallet {...modal.data} classNames={'small'}/>
    }

    else if (modal.name === 'connecting') {
        return <ConnectingWallet {...modal.data} classNames={'small'}/>
    }

    else if (modal.name === 'staking') {
        return <Staking {...modal.data}/>
    }
    else if (modal.name === 'booster-nft') {
        return <BoosterNFT {...modal.data}/>
    }
    else if (modal.name === 'no_provider') {
        return <NoProvider {...modal.data}/>
    }
    else if (modal.name === 'calculator') {
        return <Calculator {...modal.data}/>
    }

    else
        return (null);


    return <ModalContainer/>;
};

export default SystemModals;
