import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/education-bg.jpg";

function Education() {

    return (
        <Container fluid className={'section-continuous d-flex justify-content-center  align-items-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            position: 'relative'
        }}>
                <Row>
                    <Col md={8} ></Col>
                    <Col md={4}  className={'sectionText'}>
                        <div className={'sectionText mt-2'}>
                            <h2 className={'text-primary text-start'}>BULLRUN</h2>
                            <h3 className={'text-white text-start'}>EDUCATION</h3>
                        </div>
                        <h4 className={'text-white'}>WE INFORM, EDUCATE & INSPIRE</h4>
                        <p className={'moreNormal withShadow mt-5'}>
                            In such a dynamic industry, education is paramount. It is the best way to
                            introduce blockchain technology to the masses.
                        </p>
                        <p className={'moreNormal text-primary withShadow mt-2'}>
                            We have three weekly community events on various platforms such as Telegram, Twitter, and
                            Youtube.
                        </p>
                        <p className={'moreNormal withShadow mt-2'}>
                            We aim to inform, educate, and
                            inspire our community, as we believe that knowledge is the key to success.
                            An educated community is a strong
                            and informed community.
                        </p>
                    </Col>
                </Row>
        </Container>
    )
}

export default Education;
