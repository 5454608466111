import "./style.scss"
import {Modal} from "react-bootstrap";
import {useSystem} from "../../../hooks/use-system";

function ModalContainer({id, title, children, classNames, rightTopComponent}) {


    const {modal, modalVisible} = useSystem();

    const closeModal = () => {
        modal.data.onClose();
    };

    return (
        <Modal show={modalVisible ? true : false} centered={true} onHide={closeModal}
               className={`px-4 ${classNames && classNames}`}>
            <Modal.Header>
                {title && <Modal.Title>{title}</Modal.Title>}
                {rightTopComponent}
            </Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default ModalContainer