import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/roadmap-bg.jpg";
import spaceCharacters from "../../assets/images/3rd-design/roadmap-spacecharacters.png";


function RoadMap() {

    return (
        <Container fluid className={'section-continuous align-items-center justify-content-center'} id={"roadmap"}
                   style={{
                       backgroundImage: `url(${bg})`,
                       backgroundPosition: 'top',
                       backgroundSize: '100% auto',
                       backgroundColor: 'rgba(0,0,0, 0.2)',
                       position: 'relative'
                   }}>
            <Container className={''}>
                <Row className={'mb-md-0'}>
                    <Col md={12} className={'sectionText text-center my-5'}>
                        <h1 className={'text-primary'}>BULLRUN</h1>
                        <h3 className={'text-white'}>ROADMAP</h3>
                    </Col>
                </Row>
                <Row className={'d-flex align-items-center justify-content-center'} id={'phases'}>
                    <Col md={2}>
                        <h3 className={'text-primary'}>PHASE ONE COMPLETED</h3>
                        <div className={'text-white'}>
                            <p>Website & Socials</p>
                            <p>Whitepaper</p>
                            <p>Smart Contract Audit</p>
                            <p>CMC & CG application</p>
                            <p>Staking Platform</p>
                            <p>Community building</p>
                            <p>Dashboard Development</p>
                        </div>
                    </Col>
                    <Col md={8}>
                        <img src={spaceCharacters} className={'img-fluid'}/>
                    </Col>
                    <Col md={2} className={'mx-0 px-0'}>
                        <h3 className={'text-primary'}>PHASE TWO COMPLETED</h3>
                        <div className={'text-white'}>
                            <p>Limited MADZ NFT Collection</p>
                            <p>Unlimited BRL BOOSTER</p>
                            <p>DUAL Staking Platform</p>
                            <p>NFT LaunchPad</p>
                            <p>BRL BOOSTER NFT MINT</p>
                            <p>BRL Burn Calculator</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default RoadMap;
