import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/about-bg.jpg";
import tokens from "../../assets/images/3rd-design/tokens.png";
import brl from "../../assets/images/new-design/rotating-coin.gif";
import surv from "../../assets/images/3rd-design/surv.gif";
import wha from "../../assets/images/3rd-design/wha.gif";
import bigBrl from "../../assets/images/3rd-design/bigBrl.png";
import {Globe, Telegram, Twitter, Youtube} from "react-bootstrap-icons";


function AboutBullRun() {

    return (
        <Container fluid className={'section-continuous d-flex justify-content-center align-items-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: 'rgba(0,0,0, 0.2)'
        }}>
            <Container className={'mt-5'}>
                <Row>
                    <Col md={6} className={'sectionText mt-5'}>
                        <h4 className={'text-primary'}>ABOUT BULLRUN</h4>
                        <p className={'normal'}>
                            Bullrun is a utility driven token and ecosystem that
                            specifically focuses on addressing four major
                            challenges within the blockchain industry.
                        </p>
                        <p className={'normal'}>
                            We took a solution based approach as we developed the Bullrun Ecosystem.
                        </p>
                        <p className={'normal text-primary'}>
                            Our Four Pillars of Solutions are Liquidity,
                            Education, Security, and Social Network.
                        </p>
                        <p className={'normal'}>
                            There are many challenges, but also opportunities within blockchain technology. Those who
                            will come up with effective and proven solutions will benefit the
                            most and stand strong as market leaders.
                        </p>
                    </Col>
                    <Col></Col>
                    <Col md={5} className={'sectionText'}>
                        <img src={bigBrl} className={'img-fluid'}/>
                    </Col>
                </Row>
                <Row className={'mb-4 socialTokens mx-md-5 my-4'}>
                    <div className={'sectionText mt-4'}>
                        <h2 className={'text-primary text-center'}>BULLRUN</h2>
                        <h3 className={'text-white text-center'}>ECOSYSTEM TOKENS</h3>
                    </div>
                    <Col md={4} className={'text-center text-white'}>
                        <img src={brl} width={300} className={'img-fluid'}/>
                        <p className={'moreNormal'}>BULLRUN</p>
                        <div className={'tokenSocials'}>
                            <a href={'https://bullrun.life'}><Globe/></a>
                            <a href={'https://twitter.com/BullrunLife/'}><Twitter/></a>
                            <a href={'https://t.me/BullrunLife'}><Telegram/></a>
                            <a href={'https://youtube.com/@SteveBullRun?si=3gwdJSYpxow7qd-a'}><Youtube/></a>
                        </div>
                    </Col>
                    <Col md={4} className={'mt-4 text-center text-white'}>
                        <img src={surv} width={250} className={'img-fluid mb-4'}/>
                        <p className={'moreNormal'}>SURVIVOR</p>
                        <div className={'tokenSocials'}>
                            <a href={'http://survivor.ws/'}><Globe/></a>
                            <a href={'https://twitter.com/SURVIVOR_ws/'}><Twitter/></a>
                            <a href={'https://t.me/survivor'}><Telegram/></a>
                            <a href={'https://youtube.com/@BullRunLifeWomen?si=7G-dYjToXF4cdCIG'}><Youtube/></a>
                        </div>
                    </Col>
                    <Col md={4} className={'text-center text-white'}>
                        <img src={wha} width={300} className={'img-fluid'}/>
                        <p className={'moreNormal'}>KILLER WHALE</p>
                        <div className={'tokenSocials'}>
                            <a href={'https://killerwhale.fun/'}><Globe/></a>
                            <a href={'https://twitter.com/KillerWhaleKLW/'}><Twitter/></a>
                            <a href={'https://t.me/KILLERWHALEPORTAL'}><Telegram/></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default AboutBullRun;
