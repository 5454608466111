import "./style.scss"

import arrowUp from "../../assets/images/arrow-top.svg";
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";

function ScrollToTop() {

    const [visible, setVisible] = useState(false);

    const checkScroll = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100){
            setVisible(true)
        }
        else if (scrolled <= 100){
            setVisible(false)
        }
    }

    window.addEventListener('scroll', checkScroll)

    return (
        !isMobile &&
        <div id={"scrollTop"} className={visible?'visible': ''} onClick={(e) => {
            e.preventDefault()
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }}>
            <img src={arrowUp}/>
        </div>
    )

}

export default ScrollToTop;