import './style.scss'
import {Col, Container, Row} from "react-bootstrap";
import bullRotating from "../../assets/images/new-design/rotating-bull.gif"


function Abstract() {

    return (
        <Container className={'section-continuous'}>
            <Row>
                <Col md={12}>
                    <h2 className={'text-primary text-center'}>ABSTRACT</h2>
                </Col>
            </Row>
            <Row>
                <Col md={8} className={'sectionText mt-5'}>
                    <div className={'liquidityChallenge p-4'}>
                        <p>
                            BULLRUN is a utility-driven meme token focused on NFTs. BULLRUN takes a problem-solving
                            approach and tackles the main challenges we are facing in the crypto space. And a big
                            liquidity and volume The BULLRUN team developed BULLDOZER as a uniques strategy to solve
                            this problem.
                        </p>
                        <p>

                            We also use the true burn mechanism, which directly burns tokens from the total supply. This
                            combination of hyper deflation, liquidity, and volume opens the door to a sustainable
                            ecosystem.
                        </p>
                        <p>

                            We should unite crypto enthusiasts from all communities arround the world because strength
                            is in numbers.
                        </p>
                        <p>

                            The only way we can find out is if we band together and create a vibrant community that
                            values the potential of crypto.
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <img src={bullRotating} className={'img-fluid'}/>
                </Col>
            </Row>
        </Container>
    )
}

export default Abstract;