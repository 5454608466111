import './style.scss'
import {Button, Col, Container, Row} from "react-bootstrap";

import handWithPhone from "../../assets/images/3rd-design/hand_with_phone.png";
import buyBullRun from "../../assets/images/3rd-design/buy_bullrun.png";
import bg from "../../assets/images/3rd-design/home-bg.jpg";
import rotatingCoin from "../../assets/images/new-design/rotating-coin.gif";

import addresses from "../../config/addresses";
import logo from "../../assets/images/3rd-design/logo.png";
import config from "../../config";
import copyBtn from "../../assets/images/fi_copy.svg";


function Hero() {

    return (
        <div className={'section d-flex flex-column'} style={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            backgroundSize: '100% auto'
        }}>
            <Container className={'px-0 mb-auto mt-auto'} id={'home'}>
                <Row className={'d-flex align-items-center'}>
                    <Col md={6} className={'mt-4 mt-md-0 sectionText text-start'}>
                        <div className={'mt-5 text-white heroHeader'}>WELCOME TO <h2>BULLRUN</h2> <span className={'text-white'}>HYPER DEFLATIONARY <b>TOKEN</b></span></div>
                        <Row className={'mt-3'}>
                            <Col md={10}>
                                <h4 className={'mt-4'}>HOME OF THE <strong>BULLDOZER</strong></h4>
                                <p>BULLS are also symbols of honesty and abundance.
                                    If a bull has come into your life as a spirit guide,
                                    he may be urging you to be more aggressive and
                                    assertive in your pursuits.
                                    He may also be reminding you to stay focused on
                                    your goals and continue to move forward despite
                                    your obstacles in front of you.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10} className={'smAddress rounded-5 text-white mt-5 d-flex flex-row'}>
                                <div className={'mb-auto ms-0'}>
                                    <h4>SMART CONTRACT ADDRESS</h4>
                                    <a style={{color: "#FFF", fontSize: 18}} href={`https://${config.Ethscan}/token/${addresses.brl}`}
                                       target={'_blank'}><b>{addresses.brl}</b></a>
                                    <span className={'copybtn'} onClick={(e) => {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(addresses.brl);
                                    }}>
                                        <img height={22} src={copyBtn}/>

                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2} className={'m-0 p-0'}><img src={rotatingCoin} style={{position: 'relative', top: '-100px', right: '50px'}} className={'img-responsive'}/></Col>
                    <Col md={4} className={'text-end d-flex flex-column align-items-center'}>
                        <img src={handWithPhone} className={'img-responsive'} alt={'Full Bull'}/>
                        <Button className={'mb-2 emptyButton'} style={{
                            marginTop: '-100px'
                        }} onClick={(e) => {
                            window.open(`https://app.uniswap.org/#/swap?outputCurrency=${addresses.brl}`)
                        }}>
                            <img src={buyBullRun} alt={'Buy BullRun'} className={'img-fluid'}/>
                        </Button>
                        <Button className={'brandButton ms-lg-2 mt-2'} onClick={(e) => {
                            window.open('https://www.dextools.io/app/en/ether/pair-explorer/0xf0e66ec47608b99c6378e28fe395590d989b283e')
                        }}>
                            DEXTOOLS
                        </Button>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Hero;
