import "./style.scss"

import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Header from "../../sections/Header";
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "../../sections/Footer";
import {BalanceOf, BrlTotalSupply} from "../../hooks/use-dapp";
import {useContract} from "../../hooks/use-contract";
import {prettyNumber} from "../../utils/string";
import {Fetcher, Route, TokenAmount, Trade, TradeType, ETHER} from "@uniswap/sdk";
import addresses from "../../config/addresses";
import config from "../../config";
import {useEffect, useState} from "react";
import {useEtherBalance} from "@usedapp/core";
import web3 from "web3";
import BN from "bn.js";
import bullUp from "../../assets/images/bull-up-light.png";
import {useSystem} from "../../hooks/use-system";


function Dashboard({account, chainId}) {
    const contract = useContract();
    const system = useSystem();

    const {getEthPriceNow, getEthPriceHistorical} = require('get-eth-price');


    const [tokenPrices, setTokenPrices] = useState({});
    const [isLoading, showLoader] = useState(true);

    const etherBalance = useEtherBalance(account);

    const [showOnlyHoldings, setShowHoldings] = useState(localStorage.getItem('SHOW_HOLDINGS') === 'true');

    const ethers = require('ethers');
    const customHttpProvider = new ethers.providers.JsonRpcProvider(config.DappConfig.readOnlyUrls[chainId]);

    const getPrice = async (tokenAddress, ticker, name) => {
        const token = await Fetcher.fetchTokenData(chainId, tokenAddress, customHttpProvider, ticker, name);

        let pairToken = null;
        if (ticker === 'BRL') {
            pairToken = await Fetcher.fetchTokenData(chainId, addresses?.usdc, customHttpProvider, 'USDC', 'USD Coin');
        } else {
            pairToken = await Fetcher.fetchTokenData(chainId, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", customHttpProvider, 'WETH', 'Wrapped ETH');
        }
        const pair = await Fetcher.fetchPairData(token, pairToken, customHttpProvider);

        const route = new Route([pair], pairToken);

        const p = route.midPrice.invert().toSignificant(ticker === 'BRL' ? 6 : 18);

        return p ? p.toString() : "0";
    }

    const brlTotalSupply = BrlTotalSupply();
    const InitialTotalSupply = web3.utils.toWei("1000000", 'ether');
    const brlTotalBurnt = new BN(InitialTotalSupply).sub(new BN(brlTotalSupply));
    const brlTotalBurntP = brlTotalBurnt * 100 / InitialTotalSupply;

    let tokens = {
        BRL: {
            name: 'BULLRUN',
            ticker: 'BRL',
            address: addresses?.brl,
            balance: BalanceOf(contract?.brl, account),
            unit: 'ether'
        },
        ETH: {
            name: 'Ethereum',
            ticker: "ETH",
            address: addresses?.eth,
            balance: etherBalance ? etherBalance.toBigInt().toString() : "0",
            unit: 'ether'
        },
        USDC: {
            name: 'USD Coin',
            ticker: "USDC",
            address: addresses?.usdc,
            balance: BalanceOf(contract?.usdc, account),
            unit: 'mwei'
        },
        USDT: {
            name: 'USD Tether',
            ticker: "USDT",
            address: addresses?.usdt,
            balance: BalanceOf(contract?.usdt, account),
            unit: 'ether'
        },
        SHIB: {
            name: 'Shiba Inu',
            ticker: "SHIB",
            address: addresses?.shib,
            balance: BalanceOf(contract?.shib, account),
            unit: 'ether'
        },
        LINK: {
            name: 'Chainlink',
            ticker: "LINK",
            address: addresses?.link,
            balance: BalanceOf(contract?.link, account),
            unit: 'ether'
        },
        MATIC: {
            name: 'Polygon',
            ticker: "MATIC",
            address: addresses?.matic,
            balance: BalanceOf(contract?.matic, account),
            unit: 'ether'
        },
        CRO: {
            name: 'Cronos',
            ticker: "CRO",
            address: addresses?.cro,
            balance: BalanceOf(contract?.cro, account),
            unit: 'mwei'
        },
        AAVE: {
            name: 'Aave',
            ticker: "AAVE",
            address: addresses?.aave,
            balance: BalanceOf(contract?.aave, account),
            unit: 'ether'
        },
        UNI: {
            name: 'UNISWAP',
            ticker: "UNI",
            address: addresses?.uni,
            balance: BalanceOf(contract?.uni, account),
            unit: 'ether'
        },
        APE: {
            name: 'ApeCoin',
            ticker: "APE",
            address: addresses?.ape,
            balance: BalanceOf(contract?.ape, account),
            unit: 'ether'
        },
        LDO: {
            name: 'Lido DAO',
            ticker: "LDO",
            address: addresses?.ldo,
            balance: BalanceOf(contract?.ldo, account),
            unit: 'ether'
        },
        VOLT: {
            name: 'Volt Inu V2',
            ticker: "VOLT",
            address: addresses?.volt,
            balance: BalanceOf(contract?.volt, account),
            unit: 'ether'
        },
        NEURONI: {
            name: 'Neuroni AI',
            ticker: "NEURONI",
            address: addresses?.neuroni,
            balance: BalanceOf(contract?.neuroni, account),
            unit: 'ether'
        },
        MANA: {
            name: 'Decentraland',
            ticker: "MANA",
            address: addresses?.mana,
            balance: BalanceOf(contract?.mana, account),
            unit: 'ether'
        }
    }

    useEffect(() => {
        getEthPriceNow()
            .then(data => {
                Object.keys(data).forEach(k => {
                    try {
                        tokenPrices['ETH'] = data[k].ETH.USD;
                        setTokenPrices(tokenPrices);
                    } catch (err) {
                        console.log(err);
                    }
                })
            });
    });

    const [userTotalBalance, setTotalBalance] = useState(0);

    const getTotal = () => {
        let sum = 0;
        Object.keys(tokenPrices).forEach((k) => {
            var price = tokenPrices[k] || 0;
            var balance = parseFloat(tokens[k].balance) || 0;
            if (price && balance) {
                if (k == 'USDC')
                    balance *= 10 ** 12;
                else if (k == 'CRO')
                    balance *= 10 ** 10;

                sum += (price * web3.utils.fromWei(balance.toString(), 'ether'));
            }

        })

        return sum;
    }

    useEffect(() => {
        setTotalBalance(getTotal())
    }, [account])

    return (
        <Container fluid className={'align-content-center vh-100 page-container'} style={{
            backgroundColor: '#000'
        }}>
            <Header account={account}/>
            <ScrollToTop/>
            <Container className={'dashboardSection p-4 statsContainer'}>
                <Row>
                    <Col md={9} xs={7}>
                        <h1 className={'my-3 text-primary'}>BURN</h1>
                    </Col>
                </Row>
                <Row className={'dataRow'}>
                    <Col md={4} className={'mb-3'}>
                        <h3>Total Supply</h3>
                        <span>{prettyNumber(brlTotalSupply)} BRL</span>
                    </Col>
                    <Col md={4} className={'mb-3'}>
                        <h3>Burnt so far</h3>
                        <span>{prettyNumber(brlTotalBurnt)} BRL</span>
                    </Col>
                    <Col md={4}>
                        <h3>Burnt %</h3>
                        <span>{prettyNumber(brlTotalBurntP, true, false)}</span>
                    </Col>
                </Row>
                {/*<Row className={'my-3'}>*/}
                {/*    <Col md={12} className={'text-center'}>*/}
                {/*        <Button className={'brandButton ms-lg-2 mb-2'} onClick={(e) => {*/}
                {/*            system.showModal('calculator', {brlTotalSupply})*/}
                {/*        }}>*/}
                {/*            STAKING CALCULATOR <img src={bullUp} alt={'BullRun Up Arrow'}/>*/}
                {/*        </Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Container>
            <Container className={'dashboardSection assetsContainer p-4 mt-2'}>
                <Row>
                    <Col md={9} xs={7}>
                        <h1 className={'m-0'}>ASSETS</h1>
                    </Col>
                    <Col md={2} xs={5} className={'text-end switch p-md-3 p-0'}>
                        <Row className={'m-0 p-0'}>
                            <Col xs={6} className={'viewType text-center active'}>Tokens</Col>
                            <Col xs={6} className={'viewType text-center disabled'}>NFTS <span
                                className={'soon'}>soon</span> </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <Row className={'totalBalance mt-3'}>
                            <Col md={3} xs={6} className={'pt-1 pt-md-0'}><h5>Total Balance</h5></Col>
                            <Col md={6} xs={6}><p
                                style={{color: '#949494'}}>${prettyNumber(userTotalBalance.toFixed(2), false, false)}</p>
                            </Col>
                            <Col md={3}>
                                <Form.Check
                                    type="switch"
                                    id="showHoldingSwitch"
                                    label="Show only holdings"
                                    checked={showOnlyHoldings}
                                    onChange={(e) => {
                                        localStorage.setItem('SHOW_HOLDINGS', !showOnlyHoldings);
                                        setShowHoldings(!showOnlyHoldings);

                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>


                </Row>

                <Row className={'text-white mt-3 header mb-2'}>
                    <Col xs={4}>Token</Col>
                    <Col xs={4}>Price</Col>
                    <Col xs={4}>Balance</Col>
                </Row>
                {isLoading && <div className={'text-center mx-auto'}>
                    <div class="spinner-border spinner-border-lg text-light mx-auto"><span
                        class="visually-hidden">Loading...</span></div>
                </div>}
                {Object.keys(tokens).map((k) => {
                    const t = tokens[k];

                    const tmpBal = t.balance;
                    const tmpPrice = tokenPrices[t.ticker];

                    if (t.ticker !== 'ETH')
                        getPrice(t.address, t.ticker, t.name).then(value => {
                            tokenPrices[t.ticker] = value * (t.ticker === 'BRL' ? 1 : tokenPrices['ETH']);
                            setTokenPrices(tokenPrices);
                            setTotalBalance(getTotal())
                        })

                    const shouldDisplay = showOnlyHoldings ? tmpBal > 0 : true;
                    if (shouldDisplay && isLoading) {
                        showLoader(false);
                    }

                    return shouldDisplay && <Row key={t.name} className={'text-white data'}>
                        <Col xs={4}>{t.name}</Col>
                        <Col xs={4}>${tmpPrice}</Col>
                        <Col
                            xs={4}>{tmpBal ? prettyNumber(tmpBal, true, true, t.unit) : 'N/A'}
                            <br/>
                            <span>${tmpBal && tmpPrice ? prettyNumber(tmpBal, true, true, t.unit) * tmpPrice : '--'}</span></Col>
                    </Row>
                })}
            </Container>

            <Footer/>
        </Container>
    )

}

export default Dashboard;
