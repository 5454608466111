import "./style.scss"
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

import logo from "../../assets/images/3rd-design/logo.png";
import {useSystem} from "../../hooks/use-system";
import {useNavigate} from "react-router-dom";
import DisconnectBtn from "../../components/DisconnectBtn";
import brlToken from "../../assets/images/3rd-design/brl-token.png";


function Header({account}) {

    const system = useSystem();
    let navigate = useNavigate();

    const links = [
        // {
        //     label: 'Whitepaper',
        //     link: 'https://bullrun.life/BULLRUN-WHITEPAPER.pdf'
        // },
        {
            label: 'Dashboard',
            disabled: false,
            link: '/dashboard',
        },
        {
            label: 'Staking',
            link: '#staking',
            modal: 'staking'
        },
        {
            label: 'NFT',
            link: '#nft',
            modal: 'booster-nft'
        },
        {
            label: 'Launchpad',
            link: '/#launchpad'
        },

        {
            label: 'MORE',
            children: [
                {
                    label: 'Tokenomics',
                    anchor: '/#tokenomics',
                },
                {
                    label: 'Token Value Support',
                    anchor: '/#tokenValueSupport',
                },
                // {
                //     label: 'Roadmap',
                //     anchor: '/#roadmap',
                // },
                // {
                //     label: 'Team',
                //     anchor: '/#team',
                // },
                {
                    label: 'Follow us',
                    anchor: '/#followus',
                }
            ]
        }
    ]

    const rightLinks = [

    ]

    return (
        <Navbar collapseOnSelect expand="xl" bg={"dark"} variant="light" fixed={'top'}>
            <Container>
                <Navbar.Brand href="/#home" onClick={(e) => {
                    e.preventDefault()
                    navigate("/#home");
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }}>
                    <img src={logo} className={'logo'} alt={"BullRun Logo"} width={103}/>
                    <img src={brlToken} width={103} className={'ms-2'}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                        {links.map((l) => {

                            return l.children ? (
                                    <NavDropdown title={l.label} id="nav-dropdown">
                                        {l.children.map((c) => {
                                            return <NavDropdown.Item eventKey={c.anchor}
                                                                     onClick={(e) => {
                                                                         e.preventDefault();
                                                                         navigate(c.anchor);
                                                                         const element = document.getElementById(c.anchor.replace("/#", ""));
                                                                         const offset = 50;
                                                                         if (element) {
                                                                             const bodyRect = document.body.getBoundingClientRect().top;
                                                                             const elementRect = element.getBoundingClientRect().top;
                                                                             const elementPosition = elementRect - bodyRect;
                                                                             const offsetPosition = elementPosition - offset;
                                                                             window.scrollTo({
                                                                                 top: offsetPosition,
                                                                                 behavior: 'smooth'
                                                                             });
                                                                         }
                                                                     }}
                                            >{c.label}</NavDropdown.Item>
                                        })}
                                    </NavDropdown>
                                ) :

                                <Nav.Link disabled={l.disabled} href={l.anchor} key={l.anchor} onClick={(e) => {
                                    if (l.modal) {
                                        system.showModal(l.modal)
                                    }
                                    else if(l.link.indexOf('http') == 0){
                                        window.open(l.link);
                                    } else {
                                        navigate(l.link);
                                    }
                                }}>{l.label}{l.soon ? <span className={'soon'}>Soon</span>: null}</Nav.Link>
                        })}

                    </Nav>
                    <Nav className={'d-flex align-items-center'}>
                        {rightLinks.map((l) => {
                            return <Nav.Link href={l.link} target={'_blank'} key={l.label}>{l.label}</Nav.Link>
                        })}
                        <Nav.Link href="#">
                            {account ? <DisconnectBtn account={account}/> : (
                                <Button className={'brandButton'} onClick={(e) => {
                                    system.showModal('choose-wallet');
                                }}>
                                    CONNECT
                                </Button>
                            )}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

}

export default Header;
