import './style.scss'
import {Col, Container, Row} from "react-bootstrap";


function ExtraSections() {

    return (
        <Container className={'section-continuous'}>
            <Row>
                <Col md={12} className={'sectionText mt-5'}>
                    <h4 className={'text-primary'}>STOP BEING THE EXIT LIQUIDITY IN THE NAME OF ZERO TAX!</h4>
                    <p>
                        The crypto meme space is in total chaos and under attack by these MEV-bots that are draining
                        liquidity from these zero tax meme coins. If your favorite meme coin does not accumulate
                        liquidity, then you are the exit liquidity- it’s as simple as that! The MEV operator under the
                        ENS “jaredfromsubway.eth” has amassed millions of dollars from various zero tax projects, taking
                        away from crypto communities, with weak fundamentals that are not sustainable.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12} className={'sectionText mt-5'}>
                    <h4 className={'text-primary'}>BULLRUN’S LIQUIDITY THESIS - ENOUGH IS ENOUGH</h4>
                    <p>
                        Most projects are not focusing on retaining liquidity, let alone have an ecosystem dedicated to
                        do so. With the MEV-bots running rampant, draining liquidity out of the “zero tax” projects,
                        front-running trades and implementing “sandwich attacks” on their victims. This leaves the
                        communities with less liquidity and are thus THE exit liquidity.
                    </p>
                    <p>
                        Tired of being the Exit liquidity? Good! The BULLRUN ecosystem does the work for you to
                        accumulate and retain the exit liquidity with every trade. It does so through these three
                        mechanisms:
                    </p>
                    <p>
                        <ol>
                            <li>Burning BRL tokens removing them from the total supply.</li>

                            <li>USDC distributions to Staking participants.</li>

                            <li>Retention of liquidity with every trade</li>
                        </ol>
                    </p>
                    <p>
                        Liquidity is a scares commodity to come by these days. It is the bloodline for an ecosystem to
                        be sustainable and for traders to enter and exit their positions. The HODLers also enjoy USDC
                        distributions, which come from the trade volume. A win-win for everyone!
                    </p>
                    <p>
                        BULLRUN’s ecosystem is built with the community’s best interest in mind. It’s time that an
                        ecosystem works for you and not against you. That sets BULLRUN apart and changes everything!
                    </p>
                    <h4 className={'text-primary mt-5'}>THE CURRENT SITUATION</h4>
                    <p>
                        Meme coins are labeled pump and dumps and are considered short term speculations that are
                        seasonal and unsustainable. This is because they launch with no utility, mission or purpose.
                        They are dismissed as not adding any value to the industry. The zero-tax tactics leaves us
                        chasing the dream that only the insiders can attain and that is unsustainable in the long-run.
                        The MEV bots are draining liquidity from unexpecting holders, slowly devaluing our bags as we
                        helplessly watch the MEV operators fill theirs. There is high volume in these projects, but none
                        of that gets back to the community. Sadly, there are many millionaires on paper only, as there
                        is not enough <span className={'bold'}>LIQUIDITY</span> to cash out. The reality is that these paper millionaires are
                        just
                        waiting for you to be their exit liquidity!
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12} className={'sectionText mt-5'}>
                    <h4 className={'text-primary'}>THE PIONEERS</h4>
                    <p>
                        Bitcoin was the response to the financial crisis of 2008, Pepe brought back the meme hype after
                        the prolonged bear market and <span className={'bold'}>$BULLRUN IS THE MOVEMENT</span> to put an end to MEVs draining liquidity from crypto communities and their holders.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12} className={'sectionText mt-5'}>
                    <h4 className={'text-primary'}>THE MISSION & PURPOSE</h4>
                    <p>
                        ONE community with a purpose, sustainable utility from day one and a mission to accumulate the
                        deepest liquidity pool for its holders. WE BRING AWARENESS TO THE IMPORTANCE OF LIQUIDITY AND
                        SUSTAINABLE FUNDAMENTALS. This is our claim to fame that will forever change how the world views
                        crypto again!
                    </p>
                    <h4 className={'text-primary mt-5'}>THE MOVEMENT</h4>
                    <p>
                        How much longer should we sit back and do nothing about that!? I don’t know about you, but I
                        clearly see that we are being robbed of what is rightfully ours. This is a movement to reclaim
                        our liquidity, with a mission and purpose. Be part of history, as the stage is set and ready for
                        us to take over the crypto space by storm. The unsustainable, zero tax and liquidity-draining
                        meme coins have had their days, it’s time for $BULLRUN to take over.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12} className={'sectionText mt-5'}>
                    <h4 className={'text-primary'}>THE KEY TO SUCCESS</h4>
                    <p>
                        Meme coins are said to have zero use cases, when in reality they hold the biggest use case of
                        them all- the community! Large communities make waves and command attention on social media
                        platforms. It’s time to unite and claim our liquidity back! This is a movement for those who
                        want to be part of history, that will forever be in the crypto chapters, led by the largest and
                        most liquid crypto community!
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default ExtraSections;