import {useContract} from "../../../hooks/use-contract";

import greenStar from "../../../assets/images/star_sep.svg";
import doneImg from "../../../assets/images/fi_check-circle.png";
import notDoneImg from "../../../assets/images/fi_x-circle.png";
import ethNetworkImg from "../../../assets/images/networks/ethereum-logo.svg"


import "./style.scss";
import {prettyNumber, shorter} from "../../../utils/string";
import bullUp from "../../../assets/images/bull-up-light.png";
import {Button, Col, FormText, InputGroup, Row, Spinner} from "react-bootstrap";
import {useEtherBalance, useSendTransaction} from "@usedapp/core";
import web3 from "web3";
import {useEffect, useState} from "react";
import {useSystem} from "../../../hooks/use-system";
import callAPI from "../../../api";
import {useSearchParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';


function ParticipationBox({account, signed, saleInfo, userInfo, refresh}) {

    const [searchParams, setSearchParams] = useSearchParams();

    const system = useSystem();
    const contract = useContract();
    const etherBalance = useEtherBalance(account);

    const [loading, isLoading] = useState(false);
    const [twitterProfile, setTwitterProfile] = useState(null);
    const [requirements, filledRequirements] = useState({});
    const [emailError, setEmailError] = useState(false);

    const [canPreOrder, enablePreOrder] = useState(false);
    const [trx, setTrx] = useState(null);
    const [trxMessage, seTrxMessage] = useState('');
    const [boughtSpots, setBoughSpots] = useState(0);

    const getTwitterLink = () => {
        isLoading(true);
        callAPI({}, 'getTwitterLoginLink').then(resp => {
            if (resp.status) {
                localStorage.setItem('TWITTER_TOKEN', resp.payload.token);
                localStorage.setItem('TWITTER_SECRET', resp.payload.secret);
                window.location.assign(resp.payload.link)
            }
            isLoading(false);
        }).catch(err => {
            isLoading(false);
        })
    }


    const {sendTransaction, state} = useSendTransaction();

    const loader = () => {
        return `<div class="spinner-border spinner-border-sm text-light"><span
            class="visually-hidden">Loading...</span></div>`;
    }

    useEffect(() => {

        if (state.status === 'Exception') {
            console.log(state);
            isLoading(false);
            seTrxMessage(`<img src='${notDoneImg}' /> Not able to proceed with the payment`);
        } else if (state.status === 'PendingSignature') {

            seTrxMessage(`${loader()} Please sign the transaction on your wallet...`)

        } else if (state.status === 'Mining') {
            seTrxMessage(`🔐 Transaction signed! ${loader()} Waiting for the payment...`);
            setTrx(state.transaction);

        } else if (state.status === 'Success') {
            seTrxMessage(`Payment transaction succeeded! ${loader()} Validating it...`);
            callAPI({
                address: trx.hash,
                bought_spots: boughtSpots,
                email: requirements.email
            }, 'validatePayment').then(resp => {

                if (resp.status == 'SUCCESS') {
                    refresh();
                    setBoughSpots(0)
                    seTrxMessage(`<img src='${doneImg}' /> Congrats! Your spot(s) have been reserved`);
                } else {
                    seTrxMessage(`<img src='${notDoneImg}' /> ${resp.message.split("_").join(" ")}`);
                }
                isLoading(false);

            }).catch(err => {
                seTrxMessage(`Unable to validate the payment`);
                isLoading(false)
            });
        }

    }, [state])

    useEffect(() => {
        if(account && signed){
            const twitterSecret = localStorage.getItem('TWITTER_SECRET');
            callAPI({
                verifier: searchParams.get('oauth_verifier'),
                token: searchParams.get('oauth_token'),
                secret: twitterSecret
            }, 'validateTwitter').then(resp => {
                if (resp.status == 'SUCCESS') {
                    setTwitterProfile(resp.payload.user_json);
                    filledRequirements({
                        connected: resp.payload.user_json ? true : false,
                        following: resp.payload.following,
                        retweeted: resp.payload.retweeted,
                        email: requirements.email
                    })
                }
            })
        }

    }, [account, signed])

    useEffect(() => {
        if (requirements) {
            enablePreOrder(requirements.email)
        }
    }, [requirements])

    const boughtPercentage = saleInfo ? (saleInfo?.bought_spots / saleInfo?.max_spots).toFixed(2)*100 : 0;

    useEffect(() => {
        var progressLoaders = document.getElementsByClassName('progressLoader');
        if (progressLoaders && progressLoaders[0])
            progressLoaders[0].style.width = `${boughtPercentage}%`;

        // if (userInfo?.brl == 0)
        //     seTrxMessage(`<img src='${notDoneImg}' /> Sorry, Your wallet is not eligible! Please check the eligibility requirements!`)
    })


    return (
        <Col id={'participationBox'} className={'p-4 mx-4 pb-2'}>
            <Row>
                {(!account || !signed) && (
                    <div id={'boxBlocker'} className={'d-flex align-items-center justify-content-center'}>
                        <Button className={'brandButton'} onClick={(e) => {
                            system.showModal('choose-wallet');
                        }}>
                            CONNECT <img src={bullUp} alt={'BullRun Up Arrow'}/>
                        </Button>
                    </div>
                )}
                <Col xs={12}>
                    <h1 className={'sectionTitle text-center pb-2'}>PRE-ORDER DASHBOARD</h1>
                </Col>
                <Col md={4} className={'row rightBorder mx-0'}>

                    <Col xs={6}
                         className={'balanceDiv d-flex flex-column align-items-center justify-content-center mb-2'}>
                        Eligible
                        <span>{userInfo ? (userInfo.eligible ? 'Yes' : 'No') : "--"}</span>
                    </Col>
                    <Col xs={6}
                         className={'balanceDiv d-flex flex-column align-items-center justify-content-center mb-2'}>
                        ETH Balance
                        <span>{prettyNumber(etherBalance?.toBigInt().toString())}</span>
                    </Col>
                    <Col xs={6}
                         className={'balanceDiv d-flex flex-column align-items-center justify-content-center mb-2'}>
                        NFT Price
                        <span>{saleInfo ? saleInfo.price : "--"}</span>
                    </Col>
                    <Col xs={6}
                         className={'text-center balanceDiv d-flex flex-column align-items-center justify-content-center mb-2'}>
                        NFTs Pre-ordered
                        <span>{userInfo ? userInfo.bought_spots : "--"}</span>
                    </Col>
                </Col>
                <Col md={5} className={'px-md-3 requirements'}>
                    <h6 className={'my-2'}>Please follow the steps below</h6>
                    {/*<Row>*/}
                    {/*    <Col md={6}>*/}
                    {/*        <div className={''}>*/}
                    {/*            1 - <a href={'javascript:;'} aria-disabled={loading} onClick={(e) => {*/}
                    {/*            getTwitterLink();*/}
                    {/*        }}>Connect</a> your twitter*/}
                    {/*            <img src={requirements['connected'] ? doneImg : notDoneImg} height={20}*/}
                    {/*                 className={'float-end'}/>*/}
                    {/*        </div>*/}
                    {/*        <div className={''}>*/}
                    {/*            2 -*/}
                    {/*            Follow <a target={'_blank'} href={'https://twitter.com/MadzNFT'}>@MadzNFT</a>*/}
                    {/*            <img src={requirements['following'] ? doneImg : notDoneImg} height={20}*/}
                    {/*                 className={'float-end'}/>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            3 -*/}
                    {/*            Retweet this <a target={'_blank'}*/}
                    {/*                            href={'https://twitter.com/MadzNFT/status/1602355654819287047'}>post</a>*/}
                    {/*            <img src={requirements['retweeted'] ? doneImg : notDoneImg} height={20}*/}
                    {/*                 className={'float-end'}/>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col md={6} className={'my-2 mt-my-0'}>*/}
                    {/*        {twitterProfile &&*/}
                    {/*            (*/}
                    {/*                <><img className={'rounded-5'} src={twitterProfile.profile_image_url}*/}
                    {/*                       height={23}/> @{twitterProfile.screen_name}</>)*/}
                    {/*        }*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row className={''}>
                        <Col md={6}>
                            1-
                            Submit your e-mail <img src={requirements['email'] ? doneImg : notDoneImg}
                                                    height={20}
                                                    className={'float-end'}/>
                        </Col>
                    </Row>
                    <Row className={'mt-1'}>
                        <Col md={8} xs={11} className={'ms-3'}>
                            <input type={'email'} disabled={loading}
                                   className={`emailInput form-control${emailError ? ' error' : ''}`} onChange={(e) => {
                                var curValue = e.target.value;
                                var re = /\S+@\S+\.\S+/;
                                if (re.test(curValue)) {
                                    setEmailError(false)
                                    filledRequirements({
                                        connected: requirements.connected,
                                        following: requirements.following,
                                        retweeted: requirements.retweeted,
                                        email: curValue
                                    })
                                } else {
                                    setEmailError(true);
                                }
                            }}/>
                        </Col>
                    </Row>
                </Col>
                <Col md={3}
                     className={'preOrder leftBorder topBorder d-flex justify-content-evenly flex-column mt-4 pt-md-0 pt-4 mt-md-0'}>

                    {saleInfo?.show_sale_status && <Row>
                        <Col>Total</Col>
                        <Col className={'text-end'}>{boughtPercentage}%
                            ({saleInfo ? saleInfo.bought_spots : "--"}/{saleInfo ? saleInfo.max_spots : "--"})</Col>
                    </Row>}
                    {saleInfo?.show_sale_progress && <Row className={'mb-2'}>
                        <Col className={'bg-dark mx-3 px-0'}>
                            <div className={'progressLoader'}></div>
                        </Col>
                    </Row>}
                    <h6 className={'mt-2 mb-0'}>Your pre-order</h6>

                    <InputGroup className={'spotsController mx-auto my-2'}>
                        <InputGroup.Text  className={'spotsControl'} onClick={() => {
                            if(!loading)
                                setBoughSpots(boughtSpots > 0 ? boughtSpots - 1 : 0)
                        }}>-</InputGroup.Text>
                        <Form.Control disabled={loading}
                            value={boughtSpots}
                            type={'number'}
                        />
                        <InputGroup.Text  className={'spotsControl'} onClick={() => {
                            if(!loading)
                                setBoughSpots(boughtSpots < userInfo?.remaining_spots ? boughtSpots + 1 : boughtSpots)
                        }}>+</InputGroup.Text>
                    </InputGroup>

                    <Row className={'my-2'}>
                        <Col>Total Price</Col>
                        <Col className={'text-end'}><img
                            src={ethNetworkImg}/> {saleInfo ? Number(parseFloat(saleInfo.price * boughtSpots).toFixed(5)) : "0"}</Col>
                    </Row>


                    <span className={'consent'}>By preordering, you agree to MADZ <a target={'_blank'} href={'/launchpad/madz/terms'}>Terms</a></span>
                    <Button
                        disabled={!canPreOrder || loading || boughtSpots == 0 || etherBalance < web3.utils.toWei(saleInfo?.price.toString()) * boughtSpots}
                        className={'brandButton mt-2'}
                        onClick={() => {
                            isLoading(true);
                            sendTransaction({
                                to: saleInfo?.receiver_wallet,
                                value: web3.utils.toWei((saleInfo?.price * boughtSpots).toString(), 'ether')
                            });
                        }}>
                        PRE-ORDER <img src={bullUp} alt={'BullRun Up Arrow'}/>
                    </Button>
                </Col>
                <Col xs={12} className={'text-center mt-3'}>
                    <h6 dangerouslySetInnerHTML={{__html: trxMessage}}></h6>
                </Col>
            </Row>
        </Col>
    )
}

export default ParticipationBox;