import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/mission-bg.jpg";
import bullBurning from "../../assets/images/3rd-design/bull-burning.mp4";

function LiquidityKing() {

    return (
        <Container fluid className={'section-continuous d-flex justify-content-center align-items-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'center',
            backgroundSize: '100% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)'
        }}>
            <Container>
                <Row className={''}>
                    <Col md={5} className={'mt-5 sectionText'}>
                        <h4 className={'text-primary'}>THE MISSION & PURPOSE</h4>
                        <p className={'moreNormal'}>
                            ONE community with a purpose, sustainable utility from day one and a mission to accumulate the
                            deepest liquidity pool for its holders.
                        </p>
                        <p className={'moreNormal text-primary'}>
                            WE BRING AWARENESS TO THE IMPORTANCE OF LIQUIDITY AND SUSTAINABLE
                            FUNDAMENTALS.
                        </p>
                        <p className={'moreNormal'}>
                            This is our claim to fame that will forever change how the world views crypto again! a mission to
                            accumulate the deepest liquidity pool for its
                            holders.
                        </p>
                        <p className={'moreNormal text-white withShadow'}>
                            BULLRUN aims to be the <span className={'text-primary'}>most LIQUID
                            UTILITY TOKEN</span>. It has built-in mechanisms within its ecosystem to retain liquidity.
                            It is time for BULLRUN to reign supreme, as <span className={'text-primary'}>the undisputed LIQUIDITY KING</span>.
                        </p>
                    </Col>
                    <Col></Col>
                    <Col md={6} className={'sectionText'}>
                        <video src={bullBurning} className={'rounded-5'} loop={true} autoPlay={true} controls={true} muted={true} style={{
                            width: '100%', height: '100%', objectFit: 'cover'
                        }}/>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default LiquidityKing;
