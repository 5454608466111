import madzBg from "../../../../assets/images/launchpad/madz/madz_banner.svg"
import madzLogo from "../../../../assets/images/launchpad/madz/madz_logo.svg"

import {Col, Container, Row} from "react-bootstrap";
import Header from "../../../../sections/Header";
import ScrollToTop from "../../../../components/ScrollToTop";

import '../style.scss'
import Footer from "../../../../sections/Footer";


function MadzTerms() {

    return (
        <Container fluid className={'align-content-center vh-100 page-container px-0'}>
            <Header account={null}/>
            <ScrollToTop/>
            <Container fluid className={'project-banner d-flex'} style={{
                backgroundImage: `url(${madzBg})`
            }}>
                <Row className={'launch-type my-auto mx-auto p-2'}>

                    <Col xs={12}>TERMS & CONDITIONS</Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className={'text-center'}> <img src={madzLogo} className={'img-fluid'}/></Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col xs={12} className={'terms'}>
                        <p>PLEASE READ THIS SECTION CAREFULLY. IF YOU ARE IN ANY DOUBT AS TO THE ACTION YOU SHOULD TAKE, YOU SHOULD CONSULT YOUR LEGAL, FINANCIAL, TAX, OR OTHER PROFESSIONAL ADVISOR(S).</p>
                        <p><strong>DISCLAIMER</strong></p>
                        <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND UNLESS OTHERWISE SPECIFIED IN WRITING BY US, (A) THE NFTS ARE SOLD &ldquo;AS IS&rdquo; AND WITHOUT WARRANTIES OF ANY KIND, AND WE EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES WITH RESPECT TO THE NFTS, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE; (B) WE DO NOT WARRANT THAT THE TOKENS WILL BE RELIABLE, CURRENT, OR ERROR-FREE, WILL MEET YOUR REQUIREMENTS, OR THAT ERRORS IN THE TOKENS WILL BE CORRECTED; AND (C) WE CANNOT AND DO NOT WARRANT THAT THE TOKENS OR THE TOKEN DELIVERY MECHANISM WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                        <p>YOU ACKNOWLEDGE THAT YOU HAVE NOT RELIED ON ANY REPRESENTATIONS OR WARRANTIES MADE BY MADZ PROJECT OR ANYONE ELSE ON PROJECT&rsquo;S BEHALF, INCLUDING, BUT NOT LIMITED TO, CONVERSATIONS OF ANY KIND, WHETHER BY ORAL OR ELECTRONIC COMMUNICATION.</p>
                        <p>YOU UNDERSTAND THAT TOKENS, BLOCKCHAIN TECHNOLOGY, THE ETHEREUM PROTOCOL, ERC-721, ERC-1155, &amp; ERC721A ARE NEW TECHNOLOGIES BEYOND OUR CONTROL, AND THAT ADVERSE CHANGES IN MARKET FORCES OR TECHNOLOGY WILL EXCUSE OUR PERFORMANCE UNDER THESE TERMS.</p>
                        <p>TRANSACTIONS THAT USE BLOCKCHAIN TECHNOLOGY, SUCH AS NFT SALES &amp; PRESALES, ARE SUBJECT TO THE RISK OF NUMEROUS POTENTIAL FAILURES, INCLUDING HIGH NETWORK VOLUME, COMPUTER FAILURES, BLOCKCHAIN FAILURES OF ANY KIND, USER FAILURES, TOKEN THEFT, AND NETWORK HACKING. WE ARE NOT RESPONSIBLE FOR ANY LOSS OF DATA, MADZ NFTS, HARDWARE OR SOFTWARE RESULTING FROM ANY TYPE OF ERROR, THEFT OR HACKING.</p>
                        <p>MADZ NFT Project is based on the Ethereum protocol. Therefore, any malfunction, unplanned function, or unexpected operation of the Ethereum protocol may cause the MADZ NFTS Network to malfunction or operate in a manner not expected.</p>
                        <p>Some jurisdictions do not allow the exclusion of certain warranties or the waiver of implied conditions in contracts with consumers, so some or all of the exclusions of warranties and waivers in this section may not apply to you.</p>
                        <p><strong>OWNERSHIP AND COMMERCIAL OF MADZS NFTS</strong></p>
                        <p>By preordering a MADZ NFT from Bullrun life Launchpad, minting it from our smart contract, purchasing it off the secondary market (e.g. OpenSea), or otherwise acquiring the NFT MADZ through any other legitimate means or method, the Holder receives full and complete ownership, inclusive of commercial rights, to the NFT and the corresponding unique artwork. The License the Holder is receiving is solely for the Licensed NFT MADZ which includes the right to use the Licensed NFT MADZ and the right to reproduce the Licensed NFT MADZ on tribute or derivative art, merchandise, or sharing these rights with third party projects. The Holder agrees not to use, utilize, portray, advertise, distribute or otherwise market any MADZ in any project or derivative work that involves hate speech, racism, pornography, or any other illegal or unlawful content. Upon sale or transfer of the NFT, any ownership or commercial rights are immediately transferred to the new Holder. No refunds shall be issued to any Holder upon a full and complete lawful purchase of any NFT or MADZ. In the event that any Holder purchases a MADZ through the secondary market, such as OpenSea, Holder shall be held accountable and will be bound by the Terms of Service which govern said secondary market platform.</p>
                        <p><strong>ART AND CREATIVE DESIGN</strong></p>
                        <p>MADZs may bear elements of transformative fan art or caricatures which are rendered in good faith to add humor and satire to the project. Any Holder of a MADZ bearing these elements has an individual responsibility to determine the appropriateness of subsequent usage. Any Attributes associated to a MADZ is used as a parody. These attributes are not sponsored, endorsed by or affiliated by any affiliated companies and/or third party licensors.</p>
                        <p><strong>LIMITATIONS OF LIABILITY</strong></p>
                        <p>MADZ and its officers, directors, agents, joint ventures, employees, suppliers and consultants shall have no liability or responsibility for any loss arising out of or related to NFT minting and pre-order resulting from the use of the MADZ Platform or from Bullrun Life Launchpad for any technical problems, interruptions or malfunctions of the MADZ &amp; Bullrun Life Platforms, Website, Tokens, Services or otherwise. In no event shall MADZ and its officers, directors, agents, joint ventures, employees, suppliers and consultants be liable for any special, indirect, incidental, punitive or consequential damages of any kind, including, without limitation, damages caused by or resulting from a user&rsquo;s reliance on information obtained from the Company, or resulting from errors, omissions, interruptions, deletion of files or emails, errors, defects, viruses, delays in operation or transmission, or any failure of performance, whether due to an event of force majeure, suspension of Ethereum network communications, theft, destruction or unauthorized access to the Records, Services, Website, &amp; MADZ NFTs. In addition, the Company shall not be liable for any loss of profit, loss of business, loss of trading, loss of value or any other loss. This applies even if such damages are foreseeable.The above limitation of liability does not apply if MADZ or a MADZ Team caused the damage intentionally or through gross negligence.</p>
                        <p><strong>REGIONAL LIMITATIONS</strong></p>
                        <p>Citizens, nationals, residents (tax or otherwise) and/or green card holders of the following countries: (i) the United States of America; (ii) the People&rsquo;s Republic of China; (iii) South Korea; (iv) Vietnam; and (v) any other jurisdiction that prohibits the possession, dissemination or communication of the information available on the Bullrun Life launchpad (all information in the launchpad page, the Presentations and all information available on the MADZ Website are hereinafter referred to as the &ldquo;Available Information&rdquo;) and/or prohibits participation in the preorder MADZ NFTs or the offer for presale of the MADZ NFTs or similar activities or products (collectively, the &ldquo;Restricted Jurisdictions&rdquo;) or other Restricted Persons from participating in the NFT Preorder. The term &ldquo;Restricted Persons&rdquo; refers to any firm, company, partnership, trust, corporation, institution, government, state or agency of a state or any other incorporated or unincorporated body or association, union or partnership (whether or not having separate legal personality) formed and/or lawfully existing under the laws of any Restricted Jurisdiction (including, in the case of the United States of America, under the federal laws of the United States of America or the laws of any state thereof).</p>
                        <p>The MADZ NFTs do not and will not constitute securities in any jurisdiction. The MADZ Project does not constitute a prospectus or offering document of any kind and the information available is not intended as an offer of securities or a solicitation of investment in securities in any jurisdiction. The Company does not express any opinion or give any advice on the purchase, sale or other transaction of any NFT, and the presentation, publication or transmission of all or any part of the available information shall not be used or relied upon in connection with any contract or investment decision.</p>
                        <p><strong>NO GUARANTEES OR FUTURE PROMISES</strong></p>
                        <p>MADZs Creative Team cannot guarantee to accomplish every item outlined during the pre-launch planning phase as ideas and projects evolve organically. You agree that your purchase of a MADZ from initial launch of 7777 NFTs is all you are guaranteed to receive with your initial purchase, whether through primary or secondary channels. Any future benefits are ancillary to this purchase and not to be taken into consideration with your initial purchase. You agree that you are not relying on any future commitments by The MADZs Team in using this site and participating in our NFT launch.</p>
                        <p><strong>NO GUARANTEES OF VALUE</strong></p>
                        <p>MADZ NFTs were created purely as collectibles, not as investment vehicles or substitutes for cryptocurrency. We make absolutely no promise or guarantee that these NFTs will subsequently retain monetary value in fiat, cash or cryptocurrency.</p>
                        <p><strong>TAXES</strong></p>
                        <p>Each Holder is solely and entirely responsible for any and all Federal or State tax liabilities which may arise, be imposed, or enforced as a result of minting or reselling MADZ NFTs.</p>
                        <p>CLASS ACTION WAIVER, JURISDICTION AND CHOICE OF LAW</p>
                        <p>You agree to waive any class action status, and any legal dispute around the MADZs project which you may choose to bring can only be done on an individual basis.</p>
                        <p><strong>PRIVACY POLICY</strong></p>
                        <p>MADZs will not collect any cookies, IP addresses, or user data in connection with your use of the website, with the exception of cookies related to browsing behavior for the Google analytics SEO which helps us enhance your experience with our site and relay visitor traffic statistics to the Google search engine. All project participants understand that the Ethereum blockchain network is a public blockchain, and all transaction history initiated through the website will be made public. MADZs has no influence on secondary marketplaces, such as OpenSea which have independent terms of service agreed to by you, should you choose to utilize these sites. You also understand that MADZs may work with third-party apps, such as Discord or Collab.Land, which collect and store user data. Should MADZs be required to collect or store data for future activities such as merchandise launches or transfer of any benefits such as airdrops, you will be required to agree to the collection of this data. This provision may be subject to change, when deemed fit by the MADZs team upon due notification to the end user.</p>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </Container>
    )
}

export default MadzTerms;