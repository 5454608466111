import React, {useEffect, useState} from 'react';
import './style.scss'
import Modal from "../modal";
import {ChainId, useEthers, useGasPrice} from "@usedapp/core";

import {
    BalanceOf,
    PendingReward,
    UserInfo,
    Ticker,
    UseContractMethod, LastRewardTS, NFTBalanceOf, NFTApproved, USDCPerSecond
} from "../../../hooks/use-dapp";
import {useContract} from "../../../hooks/use-contract";
import {prettyNumber, shorter} from "../../../utils/string";
import {
    Button,
    Col,
    Container,
    InputGroup,
    Row,
    Form,
    Tabs,
    Tab,
    Tooltip,
    OverlayTrigger,
    Popover
} from "react-bootstrap";

import web3 from "web3";
import addresses from "../../../config/addresses";
import {useSystem} from "../../../hooks/use-system";
import config from "../../../config";
import {Fetcher, Pair, Token, TokenAmount, Trade, TradeType, WETH, Route} from "@uniswap/sdk";
import {Info} from "react-bootstrap-icons";


function Staking({...props}) {

    const maxStakableNFTs = 5;

    const system = useSystem();
    const {account, chainId} = useEthers();
    const contract = useContract();
    const gas = useGasPrice();

    const [inAction, setInAction] = useState(false);
    const [trxMessage, setTrxMessage] = useState("");
    const [staking, setStaking] = useState(true);
    const [actionAmount, setAmount] = useState("0");
    const [nftAmount, setNFTAmount] = useState("0");
    const [actionLabel, setActionLabel] = useState('STAKE')
    const [switchActionLabel, setSwitchActionLabel] = useState('-')
    const [oldStakeInfo, setOldStakeInfo] = useState({})
    const [inMigration, startMigration] = useState(false);
    const [explain, showExplanation] = useState(false);

    // const { Fetcher, WETH, Route, Trade, TokenAmount, TradeType } = require ('@uniswap/v3-sdk');
    const ethers = require('ethers');
    const customHttpProvider = new ethers.providers.JsonRpcProvider(config.DappConfig.readOnlyUrls[chainId]);

    const [price, setPrice] = useState(0);

    const getPrice = async () => {
        const brlToken = await Fetcher.fetchTokenData(chainId, addresses.brl, customHttpProvider, 'BRL', 'BULLRUN');
        const usdcToken = await Fetcher.fetchTokenData(chainId, "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", customHttpProvider, 'USDC', 'USD Coin');

        const pair = await Fetcher.fetchPairData(brlToken, usdcToken, customHttpProvider);

        const route = new Route([pair], usdcToken);
        const trade = new Trade(route, new TokenAmount(usdcToken, '100000000000000000'), TradeType.EXACT_INPUT);

        setPrice(route.midPrice.invert().toSignificant(6));
    }
    useEffect(() => {
        getPrice();
    }, [price]);


    const calculatePrice = (amount) => {
        return amount ? parseFloat(web3.utils.fromWei(amount, 'ether')) * price : 0
    }

    const switchAction = () => {
        setStaking(!staking);
    }

    const stakeUnstake = () => {
        setInAction(true);
        setTrxMessage(`${loader()} Please sign the transaction in your wallet...`)
        if (staking) {
            if (actionAmount != '0')
                send(addresses.staking, actionAmount);
            else if (nftAmount) {
                if (nftApproved)
                    deposit("0", nftAmount)
                else
                    nftSend(addresses.staking, true);
            }

        } else {
            withdraw(actionAmount ? actionAmount : "0", nftAmount ? nftAmount : "0", {
                gasPrice: gas,
                gasLimit: 500000
            });
        }
    }

    const setMax = () => {
        setAmount(getMaxAmount())
    }

    const setNFTMax = () => {
        setNFTAmount(getNFTMaxAmount())
    }

    const getMaxAmount = () => {
        return staking ? bullRunBalance : userInfo?.stakeAmount
    }

    const getNFTMaxAmount = () => {
        return staking ? Math.min(maxStakableNFTs - userInfo?.nftBalance, nftBalance) : userInfo?.nftBalance
    }

    useEffect(() => {
        setActionLabel(staking ? 'STAKE' : 'UNSTAKE')
        setSwitchActionLabel(staking ? '-' : '+')
        setAmount('0')
        setNFTAmount('0')
    }, [staking])

    const bullRunBalance = BalanceOf(contract?.brl, account);
    const rewardBalance = BalanceOf(contract?.usdc, account);
    const nftBalance = NFTBalanceOf(contract?.nft, account, "0");
    const nftApproved = NFTApproved(contract?.nft, account, addresses?.staking);
    const totalStaked = BalanceOf(contract?.brl, addresses?.staking);
    const totalNFTStaked = NFTBalanceOf(contract?.nft, addresses?.staking, "0");

    const userInfo = UserInfo(contract?.staking, account);
    const oldUserInfo = UserInfo(contract?.stakingOld, account);

    const lastRewardTS = LastRewardTS(account);
    const usdcPerSecond = USDCPerSecond();
    const pendingReward = PendingReward(account);
    const apr = () => {
        if (userInfo && lastRewardTS) {
            if (userInfo.totalRewards) {

                const multiplier = userInfo.nftBalance < maxStakableNFTs ? 70 + (userInfo.nftBalance * 6) : 100;

                const userUsdcRewardsPerYear = usdcPerSecond * 365 * 24 * 3600 * (userInfo.stakeAmount / totalStaked) * multiplier / 100

                const innerAPR = ((userUsdcRewardsPerYear * 1e12 * price) / userInfo.stakeAmount) * 100

                // const innerAPR = Math.max(0, (((userInfo.totalRewards * 365 * 24 * 3600 * 100) / userInfo.stakeAmount) / (parseInt(lastRewardTS) - userInfo.startTime)));
                return isNaN(innerAPR) ? 0 : innerAPR;
            }
        }
        return 0
    };
    const ticker = Ticker();

    const rewardTicker = "USDC";


    const rewardsPercentage = userInfo.stakeAmount && userInfo.stakeAmount > 0 ? (userInfo.nftBalance * 6) + 70 : 0;
    const rewardsBgColor = rewardsPercentage === 70 ? `rgba(179, 104, 0, 1)` : `rgba(0, 179, 25, ${(rewardsPercentage - 30) / 100})`;

    const {
        state,
        send,
        events
    } = UseContractMethod(contract?.brl, "approve");

    const {
        state: nftState,
        send: nftSend,
        events: nftEvents,
    } = UseContractMethod(contract?.nft, "setApprovalForAll");

    const {
        state: depositState,
        send: deposit,
        events: depositEvents
    } = UseContractMethod(contract?.staking, "deposit");

    const {
        state: withdrawState,
        send: withdraw,
        events: withdrawEvents
    } = UseContractMethod(contract?.staking, "withdraw");

    const {
        state: withdrawStateOld,
        send: withdrawOld,
        events: withdrawEventsOld
    } = UseContractMethod(contract?.stakingOld, "withdraw");

    const {
        state: claimState,
        send: claim,
        events: claimEvents
    } = UseContractMethod(contract?.staking, "withdraw");


    const migrate = () => {
        setOldStakeInfo(oldUserInfo);
        startMigration(true);
        setInAction(true);
        withdrawOld(oldUserInfo.stakeAmount, oldUserInfo.nftBalance);

        // check staked assets in oldUserInfo
        // if BRLs or NFTs found then
        // WithdrawOld(oldUserInfo.stakeAmount, oldUserInfo.nftAmount)
        // When withdraw finishes
        // Ask for approval on oldUserInfo.stakeAmount and on all NFts
        // Deposit(oldUserInfo.stakeAmount, oldUserInfo.nftAmount)
    }

    useEffect(() => {

        if (withdrawStateOld?.status === 'PendingSignature') {
            setTrxMessage(`🔐 ${loader()} Please sign your transaction to start migration..`);
        } else if (withdrawStateOld?.status === 'Exception') {
            setTrxMessage(withdrawStateOld.errorMessage);
            setInAction(false);
        } else if (withdrawStateOld?.status === 'Mining') {
            setTrxMessage(`🔐 Transaction Signed! ${loader()} Safely moving your assets into your wallet..`);
        } else if (withdrawStateOld?.status === 'Success') {
            const message = `✅ ${prettyNumber(oldStakeInfo.stakeAmount)} $${ticker} and ${oldStakeInfo.nftBalance} NFTs  are now in your wallet! <br/>${loader()} Please approve your BRLs... `;
            setTrxMessage(message);
            send(addresses.staking, oldStakeInfo.stakeAmount);
        }

    }, [withdrawStateOld, withdrawEventsOld])


    useEffect(() => {

        if (state?.status === 'Exception') {
            setTrxMessage(state.errorMessage);
            setInAction(false);
        } else if (state?.status === 'Mining') {

            const message = inMigration ? `${prettyNumber(oldStakeInfo.stakeAmount)}` : `${prettyNumber(actionAmount)}`;

            setTrxMessage(`🔐 Transaction Signed! ${loader()} Giving Approval for ${message} BRLs...`);
        } else if (state?.status === 'Success') {

            if (inMigration) {
                if (oldStakeInfo.nftBalance) {
                    setTrxMessage(`✅ BRL Approval Given! ${loader()} Please approve your NFTs...`);
                    nftSend(addresses.staking, true);
                } else {
                    deposit(oldStakeInfo.stakeAmount, "0", {
                        gasPrice: gas,
                        gasLimit: 500000
                    })
                }
            } else {
                setTrxMessage(`✅ Approval Given! ${loader()} Please sign staking transaction...`);
                deposit(actionAmount, "0", {
                    gasPrice: gas,
                    gasLimit: 500000
                })
            }
        }

    }, [state, events])

    useEffect(() => {

        if (nftState?.status === 'Exception') {
            setTrxMessage(nftState.errorMessage);
            setInAction(false);
        } else if (nftState?.status === 'Mining') {
            setTrxMessage(`🔐 Transaction Signed! ${loader()} Giving Approval for the NFTs...`);
        } else if (nftState?.status === 'Success') {
            setTrxMessage(`✅ Approval Given! ${loader()} Please sign staking transaction...`);
            if (inMigration) {
                deposit(oldStakeInfo.stakeAmount, oldStakeInfo.nftBalance, {
                    gasPrice: gas,
                    gasLimit: 500000
                })
            } else {
                deposit("0", nftAmount, {
                    gasPrice: gas,
                    gasLimit: 500000
                })
            }

        }

    }, [nftState, nftEvents])

    useEffect(() => {

        if (depositState?.status === 'Exception') {
            setTrxMessage(depositState.errorMessage);
            setInAction(false);
        } else if (depositState?.status === 'Mining') {
            const message = inMigration ? `` : (actionAmount != '0' ? `${prettyNumber(actionAmount)} $${ticker}` : `${nftAmount} NFTs...`);
            setTrxMessage(`🔐 Transaction Signed! ${loader()} Staking now ${message}...`);
        } else if (depositState?.status === 'Success') {
            const message = inMigration ? `Your assets are move safely to the new contract` : (actionAmount != '0' ? `✅ Staking ${prettyNumber(actionAmount)} $${ticker} is complete!` : `✅ Staking ${nftAmount} NFTs is complete!`)
            setTrxMessage(message);
            setInAction(false);
            startMigration(false);
            setAmount("0");
            setNFTAmount("0");
        }

    }, [depositState, depositEvents])

    useEffect(() => {

        if (withdrawState?.status === 'Exception') {
            setTrxMessage(withdrawState.errorMessage);
            setInAction(false);
        } else if (withdrawState?.status === 'Mining') {
            setTrxMessage(`🔐 Transaction Signed! ${loader()} Unstaking now...`);
        } else if (withdrawState?.status === 'Success') {
            const message = actionAmount != '0' ? `✅ Unstaking ${prettyNumber(actionAmount)} $${ticker} is complete!` : `✅ Unstaking ${nftAmount} NFTs is complete!`
            setTrxMessage(message);
            setInAction(false);
            setAmount("0");
            setNFTAmount("0");
        }

    }, [withdrawState, withdrawEvents])

    useEffect(() => {

        if (claimState?.status === 'Exception') {
            setTrxMessage(claimState.errorMessage);
            setInAction(false);
        } else if (claimState?.status === 'Mining') {
            setTrxMessage(`🔐 Transaction Signed! ${loader()} Claiming your rewards...`);
        } else if (claimState?.status === 'Success') {
            setTrxMessage(`✅ Rewards have been claimed!`);
            setInAction(false);
        }

    }, [claimState, claimEvents])


    useEffect(() => {
        if (!account) {
            system.showModal('choose-wallet');
        }
    }, [account])

    const loader = () => {
        return `<div class="spinner-border spinner-border-sm text-light"><span
            class="visually-hidden">Loading...</span></div>`;
    }


    const aprExplanation = (
        <Popover id="aprExplanation">
            <Popover.Header as="h4">PROJECTED APR</Popover.Header>
            <Popover.Body>
                <p>A user should not be relying upon the the APR to predict what they should have received in the past,
                    because it is a future projection based on the following variables:</p>
                <ul>
                    <li>The USDC Per second rate.</li>
                    <li>The individual user's amount of BRL currently staked.</li>
                    <li>Total BRL that is currently Staked within the Staking Contract (regularly changing).</li>
                    <li>The individual user's staking multiplier (depending on if they have nft's staked).</li>
                </ul>
                <p>This being said, the APR will change for a number of reasons:</p>
                <ul>
                    <li>every time the price of BRL changes. (all of the time)</li>
                    <li>The amount of BRL staked in the pool (in USD value) based on the reward rate that is set.</li>
                </ul>
                <p>
                    The calculation works as follows:
                    <pre>multiplier = user.nftBalance &lt; 5 ? 70 + (user.nftBalance * 6) : 100</pre>

                    <pre>userUsdcRewardsPerYear = usdcPerSecond * 365 * 24 * 3600 * (user.stakeAmount /
                    brl.balanceOf(stakingContractAddress)) * multiplier / 100</pre>

                    <pre>rewardsPerYearInBRL = userUSDCRewardsPerYear converted to BRL</pre>

                    <pre>APR = (rewardsPerYearInBRL / user.stakeAmount) * 100</pre>

                    In summary, it is a future projection assuming none of the variables will change, however, in
                    reality, the pricing of BRL and the volume of staked BRL is constantly changing so it cannot be a
                    reliable constant percentage rate.
                </p>
            </Popover.Body>
        </Popover>
    );


    return (
        <Modal {...props} title="BULLRUN STAKING" classNames={'wide'}>
            <div className={'text-center'}>
                <Container>
                    <Row className={'mb-2 mb-md-0'}>
                        <Col md={6}>
                            <div className={'stakingDescription text-start'}>

                                Stake your BRL tokens and receive USDC distributions.
                                These are based on the volume and are dynamic, depending on the market conditions.
                                <br/>
                                BULLRUN staking booster NFTs boost your distributions 6% each and you can stake a
                                maximum of five.
                                <br/><br/>
                                No staking or unstaking fees.
                                <br/>
                                No time lock periods.
                                <br/><br/>
                                <p className={'bolden'}>NOTE: A BULLRUNNER is a very rare breed!</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={'tokenBalances d-flex align-items-center justify-content-between'}>
                                <div className={'balance general text-md-end text-center'}>
                                    <h2 className={'mb-3'}>Total Staked</h2>
                                    <h4><small>{prettyNumber(totalStaked)} ${ticker} / {totalNFTStaked} NFTs</small>
                                    </h4>
                                    <h4 className={'fiatPrice'}>${prettyNumber(calculatePrice(totalStaked), true, false)}</h4>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {oldUserInfo && Object.keys(oldUserInfo).length > 0 && (oldUserInfo.stakeAmount != "0" || oldUserInfo.nftBalance != "0") &&
                            <Col md={12} className={'text-center my-2'}>
                                <div className={'stakingBox fullRadius p-4 migrationBox'}>
                                    <div className={'specialFont'}>You
                                        have {prettyNumber(oldUserInfo.stakeAmount)} BRLs
                                        and {oldUserInfo.nftBalance} NFTs in the old Staking Contract!
                                    </div>
                                    <div><small>
                                        Click on the button below to move them to the new Staking Contract!
                                    </small>
                                    </div>
                                    <div className={'mt-2'}><Button disabled={inAction}
                                                                    className={'brandButton'}
                                                                    onClick={migrate}>MIGRATE</Button></div>
                                </div>
                            </Col>
                        }
                        {inMigration &&
                            <Col md={12} className={'text-center trxMessage'}>
                                <div dangerouslySetInnerHTML={{__html: trxMessage}}></div>
                            </Col>
                        }
                        <Col md={7}>
                            <Tabs
                                defaultActiveKey="brlStaking"
                                id="stakingTabs"
                                className="mb-0"
                            >
                                <Tab eventKey="brlStaking" title="BRL Staking" disabled={inAction}>
                                    <div className={'stakingBox py-4 px-4 mb-3'}>
                                        <div
                                            className={'tokenBalances d-flex align-items-center justify-content-between'}>
                                            <div className={'balance text-start'}>
                                                <h5 className={'mb-3'}>Wallet Balance</h5>
                                                <h4 className={'mb-0'}>{prettyNumber(bullRunBalance)} ${ticker}</h4>
                                                <h4 className={'fiatPrice small'}>${prettyNumber(calculatePrice(bullRunBalance), true, false)}</h4>
                                                <h4>{prettyNumber(rewardBalance, false, true, 'mwei')} ${rewardTicker}</h4>
                                            </div>
                                            <div className={'balance text-start ps-md-3'}>
                                                <h5 className={'mb-3'}>Staked</h5>
                                                <h4 className={'mb-0'}>{prettyNumber(userInfo?.stakeAmount)} ${ticker}</h4>
                                                <h4 className={'fiatPrice small'}>${prettyNumber(calculatePrice(userInfo?.stakeAmount), true, false)}</h4>
                                                <h4 className={'fiatPrice'}>&nbsp;</h4>
                                            </div>
                                        </div>
                                        <div
                                            className={'stakingActions d-md-flex align-items-center justify-content-md-between'}>
                                            <div className={'actionInputs text-start pe-md-3 mb-2 mb-md-0'}>
                                                <InputGroup>
                                                    <Button disabled={inAction} variant="outline-secondary"
                                                            id="button-addon1"
                                                            onClick={setMax}>
                                                        Max
                                                    </Button>
                                                    <Form.Control
                                                        disabled={inAction}
                                                        placeholder={"0.00"}
                                                        value={prettyNumber(actionAmount, false)}
                                                        aria-label="amount"
                                                        type={'number'}
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => {
                                                            const tmpMax = getMaxAmount();
                                                            const curValue = e.target.value ? web3.utils.toWei(e.target.value.split(",").join("")) : "0";

                                                            setAmount(parseInt(curValue) > parseInt(tmpMax) ? tmpMax : curValue);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className={'actionButtons text-start d-flex justify-content-evenly'}>
                                                <Button disabled={inAction || !actionAmount || actionAmount === "0"}
                                                        className={'brandButton small'}
                                                        onClick={stakeUnstake}>{actionLabel}</Button>
                                                <Button disabled={inAction} className={'switchButton ration ratio-1x1'}
                                                        onClick={switchAction}>{switchActionLabel}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="nftStaking" title="Boost your staking" disabled={inAction}>
                                    <div className={'stakingBox py-4 px-4 mb-3'}>
                                        <div
                                            className={'tokenBalances d-flex align-items-center justify-content-between'}>
                                            <div className={'balance text-start'}>
                                                <h5 className={'mb-3'}>Wallet Balance</h5>
                                                <h4>{nftBalance} NFTs <Button className={'btn btn-sm mintBtn'}
                                                                              onClick={() => {
                                                                                  system.showModal('booster-nft')
                                                                              }}>Mint More</Button></h4>
                                            </div>
                                            <div className={'balance text-start'}>
                                                <h5 className={'mb-3'}>Staked</h5>
                                                <h4>{userInfo?.nftBalance} NFTs</h4>
                                            </div>
                                        </div>
                                        <div
                                            className={'rewardsProgress mt-2 mb-4 d-flex align-items-center justify-content-between'}>
                                            <div className={'progressContainer'}>
                                                <div className={'innerProgress'} style={{
                                                    background: rewardsBgColor,
                                                    width: `${rewardsPercentage}%`
                                                }}>{rewardsPercentage > 0 ? `${rewardsPercentage}% of USDC Distributions` : null}</div>
                                            </div>
                                        </div>
                                        <div
                                            className={'stakingActions d-md-flex align-items-center justify-content-md-between'}>
                                            <div className={'actionInputs text-start pe-md-3 mb-2 mb-md-0'}>
                                                <InputGroup>
                                                    <Button disabled={inAction} variant="outline-secondary"
                                                            id="button-addon1"
                                                            onClick={setNFTMax}>
                                                        Max
                                                    </Button>
                                                    <Form.Control
                                                        disabled={inAction}
                                                        placeholder={"0.00"}
                                                        value={nftAmount}
                                                        aria-label="nftAmount"
                                                        type={'number'}
                                                        aria-describedby="basic-addon2"
                                                        onChange={(e) => {
                                                            const tmpMax = getNFTMaxAmount();
                                                            const curValue = e.target.value ? e.target.value : "0";

                                                            setNFTAmount(parseInt(curValue) > parseInt(tmpMax) ? tmpMax : curValue);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className={'actionButtons text-start d-flex justify-content-between'}>
                                                <Button disabled={inAction || !nftAmount || nftAmount === "0"}
                                                        className={'brandButton small'}
                                                        onClick={stakeUnstake}>{actionLabel}</Button>
                                                <Button disabled={inAction} className={'switchButton ration ratio-1x1'}
                                                        onClick={switchAction}>{switchActionLabel}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                        <Col md={5} className={'mt-1 pt-2'}>
                            <div className={'stakingBox fullRadius py-4 px-4 mt-4'}>
                                <div className={'tokenBalances d-flex align-items-center justify-content-between'}>
                                    <div className={'balance full text-start'}>
                                        <h5 className={'mb-3'}>Pending Rewards</h5>
                                        <h4>{prettyNumber(pendingReward, false, true, 'mwei')} ${rewardTicker}</h4>
                                    </div>
                                </div>
                                <div className={'tokenBalances d-flex align-items-center justify-content-between'}>
                                    <div className={'balance full text-start'}>
                                        <h5 className={'mb-0'}>PROJECTED APR
                                            <OverlayTrigger show={explain} overlay={aprExplanation} placement={'auto'}>
                                                <Button className={'aprInfo ms-2'} onClick={() => showExplanation(!explain)}><Info /></Button>
                                            </OverlayTrigger>

                                        </h5>
                                        <h4>{prettyNumber(apr(), true, false)} %</h4>
                                    </div>
                                </div>
                                <div className={'stakingActions d-flex align-items-center justify-content-between'}>
                                    <div className={'actionButtons full text-start d-flex  justify-content-between'}>
                                        <Button style={{width: '100%'}} disabled={inAction || pendingReward === "0"}
                                                className={'switchButton full'}
                                                onClick={() => {
                                                    claim("0", "0");
                                                    setInAction(true);
                                                }}>CLAIM</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {!inMigration &&
                            <Col md={12} className={'text-center trxMessage'}>
                                <div dangerouslySetInnerHTML={{__html: trxMessage}}></div>
                            </Col>
                        }
                    </Row>
                </Container>

            </div>
        </Modal>
    );
}

export default Staking;
