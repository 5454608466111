import "./style.scss"
import {Button, Container, Nav, Navbar} from "react-bootstrap";

function Footer({account}) {

    return (
        <Container>
            <footer className="py-3 mt-2 mb-5">
                {/*<p className="col-md-3 mb-0 text-muted">*/}
                {/*    <img src={logo} width={300}/>*/}
                {/*</p>*/}
                <p href="/"
                   className="copyright text-center col-md-12 mb-3 mb-md-0 me-md-auto">
                    All rights reserved. Copyright ©2023 <br/>
                    Powered by <a className={'text-decoration-none'} href={'https://www.blacklemon.me'} target={'_blank'}>BLACK LEMON</a> .
                </p>
                {/*<ul className="nav col-md-5 justify-content-end footerLinks">*/}
                {/*    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">DISCLAIMER</a></li>*/}
                {/*    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">SMART CONTRACT ADDRESS</a></li>*/}
                {/*    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">AUDIT REPORT</a></li>*/}
                {/*</ul>*/}
            </footer>
        </Container>
    )

}

export default Footer;
