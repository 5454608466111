import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SystemProvider} from "./hooks/use-system";
import SystemModals from "./components/modals";
import {DAppProvider} from "@usedapp/core";
import config from "./config";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <DAppProvider config={config.DappConfig}>
            <SystemProvider>
                <App/>
                <SystemModals/>
            </SystemProvider>
        </DAppProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
