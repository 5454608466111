import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import greenArrowUp from "../../assets/images/green-up-arrow.png"

function WhyBullRun() {

    return (
        <Container className={'section-continuous d-flex align-items-center'}>
            <Row>
                <Col className={'mx-md-5 sectionText'} style={{
                    background: `url(${greenArrowUp}) right top no-repeat`
                }}>
                    <h1>WHY BULLRUN?</h1>
                    <p>
                        BULLRUN, which was created in a harsh bear market, unites all cryptocurrency lovers who yearn for the upcoming bull run. Crypto communities are fun and vibrant. But the vast majority of the projects do not have a long-term vision. Our goal is to create a sustainable, inclusive community with interests in line with the BULLISH ethos of BULLRUN.
                        <br/><br/>
                        <b style={{fontWeight: 900}}>BULLRUN</b> is community driven and it focuses on developing sustainable and hyper-deflationary tokens. The BULLRUN ecosystem’s main purpose is to solve liquidity and volume challenges. It does that by implementing:</p>
                </Col>
            </Row>
        </Container>
    )
}

export default WhyBullRun;