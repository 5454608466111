import addresses from '../config/addresses';

import {Contract} from "@ethersproject/contracts";

import {utils} from "ethers";
import bullRunABI from "../abis/BullRun_ABI.json";
import erc20ABI from "../abis/erc20.json";
import stakingABI from "../abis/BullRunNFTStaking_ABI.json";
import nftABI from "../abis/BRLNFT_ABI.json";

export const useContract = () => {

    const brlInterface = new utils.Interface(bullRunABI);
    const stakingInterface = new utils.Interface(stakingABI);
    const nftInterface = new utils.Interface(nftABI);
    const erc20Interface = new utils.Interface(erc20ABI);

    return {
        brl: new Contract(addresses.brl, brlInterface),
        usdc: new Contract(addresses.usdc, erc20Interface),
        usdt: new Contract(addresses.usdt, erc20Interface),
        shib: new Contract(addresses.shib, erc20Interface),
        matic: new Contract(addresses.matic, erc20Interface),
        cro: new Contract(addresses.cro, erc20Interface),
        mana: new Contract(addresses.mana, erc20Interface),
        sand: new Contract(addresses.sand, erc20Interface),
        neuroni: new Contract(addresses.neuroni, erc20Interface),
        ape: new Contract(addresses.ape, erc20Interface),
        ldo: new Contract(addresses.ldo, erc20Interface),
        volt: new Contract(addresses.volt, erc20Interface),
        aave: new Contract(addresses.aave, erc20Interface),
        uni: new Contract(addresses.uni, erc20Interface),
        link: new Contract(addresses.link, erc20Interface),
        staking: new Contract(addresses.staking, stakingInterface),
        stakingOld: new Contract(addresses.stakingOld, stakingInterface),
        nft: new Contract(addresses.nft, nftInterface)
    };
};

export default {};