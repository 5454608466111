import './style.scss'
import {Col, Container, Row} from "react-bootstrap";
import nftRotating from "../../assets/images/new-design/nft.gif";
import bg from "../../assets/images/3rd-design/booster-bg-gold.jpg";
import allCoins from "../../assets/images/3rd-design/all-coins.png";


function BoosterNFT() {

    return (
        <Container fluid className={'section-continuous d-flex align-items-center justify-content-center'} id={"utilities"}
                   style={{
                       backgroundImage: `url(${bg})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundPosition: 'top',
                       backgroundSize: 'cover',
                       backgroundColor: 'rgba(0,0,0, 0.2)',
                       position: 'relative'
                   }}>
            <Container>
                <Row md={12} className={''}>
                    <Col md={5}
                         className={'sectionText px-5 d-flex flex-column align-items-center justify-content-center'}>
                        <h2 className={'text-primary text-center'}>BULLRUN</h2>
                        <h3 className={'text-white text-center'}>STAKING BOOSTER NFT</h3>
                        <img src={nftRotating} className={'img-fluid'}/>
                    </Col>

                    <Col md={7} className={'boosterNFTText text-white py-4 d-flex flex-row align-items-center justify-content-center'}>
                        <div className={'px-4'}>
                            <ul>
                            <li><p>
                                By Staking only your BRL Tokens without
                                the BULLRUN BOOSTER NFT,
                                you are limited to only 70% Of USDC distributions.
                            </p>
                            </li>
                                <li>
                            <p>

                                However, with every additional BULLRUN BOOSTER NFT, you recieve 6% on each BOOSTER NFT,
                                for a maximum of FIVE BOOSTERS In total.
                            </p>
                                </li>
                                <li>
                            <p>
                                By Staking Five BOOSTER NFTs, You unlock 100% of your USDC distributions.
                            </p>
                                </li>
                                <li>
                            <p>
                                The BOOSTERS also serve as a scalable source of
                                revenue, which are used to feed into the liquidity pool.
                            </p>
                                </li>
                                <li>
                            <p>
                                This enables BULLRUN to build and sustain a healthy
                                liquidity.
                            </p>
                                </li>
                                <li>
                            <p>
                                Volume dictates the USDC distribution amounts.
                            </p>
                                </li>
                            </ul>
                            <br/><br/>
                            <p>
                                <b>
                                As long as there are ongoing trades on Uniswap, the USDC distributions will be
                                perpetually
                                accumulated by the Staking participants.
                                </b>
                            </p>

                        </div>
                        <img src={allCoins} height={500}/>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default BoosterNFT;
