import React from 'react';

import logoMetaMask from "../../../assets/images/wallets/metamask.svg"
import logoWalletConnect from "../../../assets/images/wallets/walletconnect.svg"
import logoCoinBaseWallet from "../../../assets/images/coinbase.jpeg";
import './style.scss'
import {useEthers} from "@usedapp/core";
import config from "../../../config";
import Modal from "../modal";
import {useSystem} from "../../../hooks/use-system";
import {isMobile} from "react-device-detect";


function ChooseWallet({...props}) {

    const {account, activate, chainId} = useEthers();
    const envChainId = config.chainId;

    const system = useSystem();

    React.useEffect(() => {
        if (chainId && window.ethereum) {
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: '0x' + envChainId}],
            });
        }
    }, [chainId, envChainId]);


    let wallets = [
        {
            connector: config.Connectors.injected,
            label: 'Metamask',
            img: logoMetaMask,
            storageValue: 'Injected'
        },
        {
            connector: config.Connectors.walletconnect,
            label: 'WalletConnect',
            img: logoWalletConnect,
            storageValue: 'WalletConnect'
        },
        {
            connector: config.Connectors.Coinbase,
            label: 'Coinbase',
            img: logoCoinBaseWallet,
            storageValue: 'coinbase'
        }
    ]


    return (
        <Modal {...props} title="CONNECT WALLET">
            <div>

                {wallets.map((w) => {
                    return <div className={'wallet mb-3'} key={w.label}>
                        <button
                            className="btn btn-lg d-flex w-100 align-items-center justify-content-between py-3"
                            onClick={() => {
                                activate(w.connector).catch((error) => {
                                    system.showModal('');
                                })
                                localStorage.setItem(config.constants.STORAGE.CONNECTOR, w.storageValue);

                                if (!account) {
                                    system.showModal('connecting', {wallet: w.label, icon: w.img});
                                } else
                                    system.showModal('');
                            }}
                        >
                            <span className={'label'}>{w.label}</span>
                            <span className="icon coin mr-2">
                                <img src={w.img} style={{borderRadius: '50%', overflow: 'hidden'}}
                                     className={'img-fluid'} alt={'Metamask Logo'}/>
                            </span>
                        </button>
                    </div>
                })}
            </div>
        </Modal>
    );
}

export default ChooseWallet;
