import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bullForV from "../../assets/images/bull_for_v.svg";
import starSep from "../../assets/images/star_sep.svg";
import solidityLogo from "../../assets/images/solidity.png";
import download from "../../assets/images/fi_download.svg";
import addresses from "../../config/addresses";
import config from "../../config";
import {isMobile} from "react-device-detect";
import copyBtn from "../../assets/images/fi_copy.svg";
import bg from "../../assets/images/3rd-design/tokenvalue-bg.jpg";
import logo from "../../assets/images/3rd-design/logo.png";

import {useState} from "react";

function TokenValueSupport() {

    const [copyText, setCopyText] = useState('Copy')

    const trxTypes = [
        {
            label: 'BUY TAX',
            perentage: '6%',
            distribution: [
                // {
                //     label: 'Reflections',
                //     perentage: '3%',
                // },
                {
                    label: 'True Burn',
                    perentage: '1%',
                },
                {
                    label: 'USDC DISTRIBUTIONS',
                    perentage: '1%',
                },
                {
                    label: 'LP (swap & liquify)',
                    perentage: '3%',
                },
                {
                    label: 'ECO/OPS',
                    perentage: '1%',
                },
                // {
                //     label: 'Farming wallet',
                //     perentage: '1%',
                // }
            ]
        },
        {
            label: 'SELL TAX',
            perentage: '6%',
            distribution: [
                // {
                //     label: 'Reflections',
                //     perentage: '4%',
                // },
                {
                    label: 'True Burn',
                    perentage: '1%',
                },
                {
                    label: 'USDC DISTRIBUTIONS',
                    perentage: '1%',
                },
                {
                    label: 'LP (swap & liquify)',
                    perentage: '3%',
                },
                {
                    label: 'ECO/OPS',
                    perentage: '1%',
                },
                // {
                //     label: 'Farming wallet',
                //     perentage: '1%',
                // }
            ]
        }
    ]

    return (
        <Container fluid className={'d-flex section-continuous align-items-center justify-content-center'}
                   id={"utilities"} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top',
            backgroundSize: '100% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            position: 'relative',
            overflow: 'hidden'
        }} id={"tokenValueSupport"}>
            <Container>
                <Row>
                    <Col xs={12} className={'sectionText text-center mb-5'}>
                        <h1>BULLRUN</h1>
                        <h3 className={'text-white'}>TOKEN VALUE SUPPORT</h3>
                    </Col>
                    <Col xs={12}>
                        <Row className={'mb-3 smartContract'}>
                            <Col md={6} style={{backgroundColor: '#000'}} className={'mx-auto smAddress rounded-5 text-white py-2 d-flex flex-row'}>
                                <img src={logo} width={70}/>
                                <div className={'my-auto ms-2'}>
                                    Smart Contract Address<br/>
                                    <a href={`https://${config.Ethscan}/token/${addresses.brl}`}
                                       target={'_blank'}><b>{addresses.brl}</b></a>
                                    <span className={'copybtn'} onClick={(e) => {
                                            e.preventDefault();
                                            navigator.clipboard.writeText(addresses.brl);
                                        }}>
                                        <img height={22} src={copyBtn}/>

                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className={'tokenValueDistribution text-center px-0'}>
                        {trxTypes.map((t) => {
                            return (
                                <Row
                                    className={'mb-3 px-2 bg-primary rounded-5 d-flex justify-content-center align-items-center'}>
                                    <Col md={4} className={'text-center'}>
                                        <div>
                                            <span className={'percentage'}>{t.perentage} </span>
                                            <span className={'label'}>{t.label}</span>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <Row className={'text-center'}>
                                            {t.distribution.map((d, i) => {
                                                const cls = `my-0 ${i == 0 || i == 2 ? 'bg-dark' : ''}`
                                                return (
                                                    <Col md={6} className={cls}>
                                                        <div className={'labelAndPercentage'}> {d.label}: {d.perentage}
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })}

                        <Row className={'mb-3 extraStats mt-2 mb-4 px-md-2'}>

                            <Col md={3} className={'text-md-center text-center bg-dark rounded-5'}>
                                Slippage: 7% on buy/sell
                            </Col>
                            <Col md={1}></Col>
                            <Col md={3} className={'text-md-center text-center bg-dark rounded-5'}> AntiBot
                                Feature</Col>
                            <Col md={1}></Col>
                            <Col md={3} className={'text-md-center text-center bg-dark rounded-5'}> Max
                                wallet: 1%</Col>
                            <Col md={2}></Col>
                            <Col md={3} className={'text-md-center text-center mt-md-3 bg-dark rounded-5'}> Max
                                Tx.
                                on buy/sell: 0.5%</Col>
                            <Col md={1}></Col>
                            <Col md={3} className={'text-md-center text-center mt-md-3 bg-dark rounded-5'}> LP
                                locked for 10 years</Col>
                        </Row>
                        {/*<Row className={'mb-3 auditReport py-4'}>*/}
                        {/*    <Col md={5} className={'d-flex align-items-center justify-content-center'}><a*/}
                        {/*        href={'https://solidity.finance'} target={'_blank'}><img src={solidityLogo}*/}
                        {/*                                                                 height={100}/></a></Col>*/}
                        {/*    <Col md={6}>*/}
                        {/*        <div className={'downloadContainer px-2 py-3 mt-2'}>*/}
                        {/*            <a href={'https://solidity.finance/audits/BullRun/'} target={'_blank'}> <img*/}
                        {/*                src={download}/> Audit Report</a>*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default TokenValueSupport;

