import bullRunABI from "../abis/BullRun_ABI.json";
import bullRunBoosterNFTABI from "../abis/BRLNFT_ABI.json";
import stakingABI from "../abis/BullRunNFTStaking_ABI.json";
import {utils} from "ethers";
import {Contract} from "@ethersproject/contracts";
import addresses from "../config/addresses";

const {useCall, useContractFunction} = require("@usedapp/core");

const brlInterface = new utils.Interface(bullRunABI);
const stakingInterface = new utils.Interface(stakingABI);
const nftInterface = new utils.Interface(bullRunBoosterNFTABI);

const brlContract = new Contract(addresses.brl, brlInterface);
const stakingContract = new Contract(addresses.staking, stakingInterface);
const BoosterNFTContract = new Contract(addresses.nft, nftInterface);


const UseContractMethod = (contractObj, methodName) => {
    const {state, send, events} = useContractFunction(contractObj, methodName, {});
    return {state, send, events};
};


const BalanceOf = (tokenContract, account) => {

    const balance = useCall({
        contract: tokenContract,
        method: "balanceOf",
        args: [account],
    });

    return balance && balance.value ? balance.value[0].toBigInt().toString() : "0";
};

const NFTBalanceOf = (tokenContract, account, tokenId) => {

    const balance = useCall({
        contract: tokenContract,
        method: "balanceOf",
        args: [account, tokenId],
    });


    return balance && balance.value ? balance.value[0].toBigInt().toString() : "0";
};
const NFTPrice = () => {

    const balance = useCall({
        contract: BoosterNFTContract,
        method: "ethPrice",
        args: [],
    });


    return balance && balance.value ? balance.value[0].toBigInt().toString() : "0";
};

const NFTApproved = (tokenContract, account, spender) => {

    const approved = useCall({
        contract: tokenContract,
        method: "isApprovedForAll",
        args: [account, spender],
    });


    return approved && approved.value ? approved.value[0]: false;
};

const Ticker = () => {

    const ticker = useCall({
        contract: brlContract,
        method: "symbol",
        args: [],
    });

    return ticker && ticker.value ? ticker.value[0]: "";
}

const StakingStarted = () => {

    const stakingStarted = useCall({
        contract: stakingContract,
        method: "started",
        args: [],
    });

    return stakingStarted && stakingStarted.value ? stakingStarted.value[0] : false;
}

const Delay = () => {

    const delay = useCall({
        contract: brlContract,
        method: "delay",
        args: [],
    });

    return delay && delay.value && delay.value !== undefined ? delay.value[0].toBigInt().toString() : "0";
}


const UserInfo = (contract, account) => {

    const userInfo = useCall({
        contract: contract,
        method: "userInfo",
        args: [account],
    });
    var userObj = {};

    if(userInfo && userInfo.value){
        userObj = {
            stakeAmount: userInfo.value.amount.toBigInt().toString(),
            totalRewards: userInfo.value.totalRewards.toBigInt().toString(),
            nftBalance: userInfo.value.nftBalance.toBigInt().toString(),
            startTime: userInfo.value.startTime
        }
    }

    return userObj;
};

const PendingReward = (account) => {

    const reward = useCall({
        contract: stakingContract,
        method: "pendingUSDC",
        args: [account],
    });

    return reward && reward.value ? reward.value[0].toBigInt().toString(): "0";
};
const LastRewardTS = (account) => {

    const rewardTs = useCall({
        contract: stakingContract,
        method: "lastRewardTimestamp",
        args: [],
    });

    return rewardTs && rewardTs.value? rewardTs.value[0].toBigInt().toString() : "0";
};

const USDCPerSecond = () => {

    const usdcPerSec = useCall({
        contract: stakingContract,
        method: "usdcPerSecond",
        args: [],
    });

    return usdcPerSec && usdcPerSec.value? usdcPerSec.value[0].toBigInt().toString() : "0";
};

const BrlTotalSupply = () => {
    const totalSupply = useCall({
        contract: brlContract,
        method: "totalSupply",
        args: [],
    });

    return totalSupply && totalSupply.value? totalSupply.value[0].toBigInt().toString() : "0";
}

export {
    UseContractMethod,
    BalanceOf,
    NFTBalanceOf,
    NFTApproved,
    NFTPrice,
    UserInfo,
    LastRewardTS,
    USDCPerSecond,
    PendingReward,
    StakingStarted,
    Ticker,
    Delay,
    BrlTotalSupply
}