import './style.scss'
import {Col, Container, Row} from "react-bootstrap";
import steveImg from "../../assets/images/steve.png"
import awinoImg from "../../assets/images/awino.png"
import {Twitter, Youtube} from "react-bootstrap-icons";

function Team() {

    const team = [
        {
            name: 'Steve',
            title: 'Founder & Ecosystem Developer',
            profile: steveImg,
            socials: [
                {
                    icon: <Twitter color={'#000'} size={28}/>,
                    link: 'https://twitter.com/SteveBullRun'
                },
                // {
                //     icon: <Youtube color={'#000'} size={28} />,
                //     link: 'https://youtube.com/c/BeCreative2'
                // }
            ]
        },
        {
            name: 'Awino',
            title: 'Founder & Ecosystem Developer',
            profile: awinoImg,
            socials: [
                {
                    icon: <Twitter color={'#000'} size={28}/>,
                    link: 'https://twitter.com/AwinoBullRun'
                }
            ]
        }
    ]

    return (
        <Container className={'section-continuous d-flex align-items-center justify-content-center'} id={"team"}>
            <Row className={'mb-5 mb-md-0'}>
                <Col md={12} className={'sectionText text-center mb-5'}>
                    <h1>TEAM</h1>
                </Col>
                <Col md={2}></Col>
                {team.map((u, i) => {
                    return <Col md={4} className={'mb-3 mb-md-0'} key={`${i}${u.name}`}>
                        <div className={'team p-4'}>
                            <div className={'teamProfile mb-2 text-center mb-5'}>
                                <img src={u.profile} className={'img-fluid'} alt={u.name}/>
                            </div>
                            <div className={'teamName mb-2 text-center'}>{u.name}</div>
                            <div className={'teamTitle text-center'}>{u.title}</div>
                            <div className={'teamSocials mt-4 text-center d-flex align-items-center'}>
                                {u.socials.map((e) => {
                                    return <div key={e.link} className={'social mx-auto'}><a target={'_blank'} rel={"noreferrer"}
                                                                                href={e.link}>{e.icon}</a></div>
                                })}
                            </div>
                        </div>
                    </Col>
                })}
            </Row>
    </Container>
)
}

export default Team;