import madzBg from "../../../assets/images/launchpad/madz/madz_banner.svg"
import madzLogo from "../../../assets/images/launchpad/madz/madz_logo.svg"
import ethNetworkImg from "../../../assets/images/networks/ethereum-logo.svg"
import collabThunder from "../../../assets/images/launchpad/collab_thunder.svg";
import greenStar from "../../../assets/images/star_sep.svg";
import greenCircle from "../../../assets/images/launchpad/green_circle.png";

import creatorzHelmet from "../../../assets/images/launchpad/madz/creatorz.svg";
import misfitzHelmet from "../../../assets/images/launchpad/madz/misfitz.svg";
import gamerzHelmet from "../../../assets/images/launchpad/madz/gamerz.svg";
import cryptomaniczHelmet from "../../../assets/images/launchpad/madz/cryptomaniacz.svg";
import jokesterzHelmet from "../../../assets/images/launchpad/madz/jokesterz.svg";
import weekenderzHelmet from "../../../assets/images/launchpad/madz/weekenderz.svg";

import ghassub from "../../../assets/images/launchpad/madz/team/ghassub.svg";
import assaad from "../../../assets/images/launchpad/madz/team/assaad.svg";
import bruno from "../../../assets/images/launchpad/madz/team/bruno.svg";
import samer from "../../../assets/images/launchpad/madz/team/samer.svg";
import elias from "../../../assets/images/launchpad/madz/team/elias.svg";
import angela from "../../../assets/images/launchpad/madz/team/angela.svg";
import alex from "../../../assets/images/launchpad/madz/team/alex.svg";
import aya from "../../../assets/images/launchpad/madz/team/aya.svg";
import aline from "../../../assets/images/launchpad/madz/team/aline.svg";
import perla from "../../../assets/images/launchpad/madz/team/perla.svg";
import mohamad from "../../../assets/images/launchpad/madz/team/mohamad.svg";
import kc from "../../../assets/images/launchpad/madz/team/kc.svg";
import patrick from "../../../assets/images/launchpad/madz/team/patrick.svg";
import swatantra from "../../../assets/images/launchpad/madz/team/swatantra.svg";
import issa from "../../../assets/images/launchpad/madz/team/issa.svg";

import stakingBoosterImg from "../../../assets/images/launchpad/madz/booster.jpg";


import circleThunder from "../../../assets/images/launchpad/thunder_circle.svg";

import {Col, Container, Row} from "react-bootstrap";
import {Globe, Twitter} from "react-bootstrap-icons";

import "./style.scss";
import {useEffect, useRef, useState} from "react";
import ParticipationBox from "../ParticipationBox";
import callAPI from "../../../api";
import starSep from "../../../assets/images/star_sep.svg";
import Marquee from "react-fast-marquee";
import CountdownTimer from "../../../components/CountdownTimer";


function Madz({type, account, signed}) {

    const [collectionInfo, setCollectionInfo] = useState(null);
    const [saleStarted, startSale] = useState(null);
    const [soldOut, setSoldOut] = useState(false);
    const [refresh, incRefresh] = useState(0);

    const projectFeatures = [
        // {
        //     title: 'Preorder Price',
        //     value: '0.1 ETH'
        // },
        {
            title: 'Eligibility Criteria',
            value: 'BRL Holderz'
        },
        {
            title: 'Preorder Allocation',
            value: `${collectionInfo ? collectionInfo.sale?.max_spots : '1269'} / 7777 FCFS`
        },
        {
            title: 'Collab Rewards',
            value: 'BRL Staking Booster NFT'
        }
    ]

    const collabTerms = [
        {
            term: 'Participants will be rewarded with Staking Booster NFTs that once staked with BRL will maximize $USDC staking distributions.'
        },
        {
            term: `${collectionInfo ? collectionInfo.sale?.max_spots : '--'} NFTs reserved for Bullrunners`
        },
        {
            term: 'Pre-sale is held on FCFS (first-come, first-serve) basis'
        },
        {
            term: 'BRL holders snapshots will be taken few days prior to the PRE-Sale Date'
        }

    ]

    const helmets = [
        {
            img: creatorzHelmet
        },
        {
            img: misfitzHelmet
        },
        {
            img: gamerzHelmet
        },
        {
            img: cryptomaniczHelmet
        },
        {
            img: jokesterzHelmet
        },
        {
            img: weekenderzHelmet
        }
    ]

    const team = [
        {
            img: ghassub,
            name: 'Ghassub',
            title: 'CEO'
        },
        {
            img: bruno,
            name: 'Bruno',
            title: 'COO'
        },
        {
            img: assaad,
            name: 'Assaad',
            title: 'CTO'
        },
        {
            img: samer,
            name: 'Samer',
            title: 'Digital 3D/2D Artist'
        },
        {
            img: elias,
            name: 'Elias',
            title: 'Software Engineer'
        },
        {
            img: angela,
            name: 'Angela',
            title: 'Digital Designer'
        },
        {
            img: alex,
            name: 'Alex',
            title: 'UX/UI Designer'
        },
        {
            img: aya,
            name: 'Aya',
            title: 'Communication Manager'
        },
        {
            img: aline,
            name: 'Aline',
            title: 'Art Director'
        },
        {
            img: perla,
            name: 'Perla',
            title: `Creative<br/>Communications Director`
        }
        ,
        {
            img: kc,
            name: 'KC',
            title: 'Communications Director'
        },
        {
            img: mohamad,
            name: 'Mohamad',
            title: 'Digital Project Manager'
        },
        {
            img: patrick,
            name: 'Patrick',
            title: 'Outreach Manager'
        },
        {
            img: swatantra,
            name: 'Swatantra',
            title: 'Community Admin'
        }
        ,
        {
            img: issa,
            name: 'Issa',
            title: '3D Artist'
        }
    ]

    const eligibilities = [
        {
            rule: '50+BRL',
            outcome: '1 MAD PASS NFT'
        },
        {
            rule: '100+BRL',
            outcome: '2 MAD PASS NFTs'
        },
        {
            rule: '200+BRL',
            outcome: '4 MAD PASS NFTs'
        },
        {
            rule: '300+BRL',
            outcome: '6 MAD PASS NFTs'
        },
        {
            rule: '500+BRL',
            outcome: '10 MAD PASS NFTs'
        },
        {
            rule: '1000+BRL',
            outcome: '25 MAD PASS NFTs'
        },
        {
            rule: '4000+BRL',
            outcome: '100 MAD PASS NFTs'
        }

    ]

    const videoRef = useRef();
    const saleDateStr = (saleDt) => {

        return <CountdownTimer date={new Date(`${saleDt}`)}/>;
    }


    useEffect(() => {

        // videoRef.current.onended = function () {
        //     videoRef.current.src = 'https://cdn.madz.wtf/videos/madz_trailer.mp4';
        //     document.getElementsByClassName('video-toggle')[0].style.display = 'block';
        // };

        if (!collectionInfo)
            callAPI({}, 'collectionInfo').then(resp => {
                if (resp.status == 'SUCCESS') {
                    setCollectionInfo(resp.payload.collection);
                    startSale(new Date(resp.payload.collection.sale.sale_dt) <= new Date())
                    setSoldOut(resp.payload.collection.sale.bought_spots >= resp.payload.collection.sale.max_spots)
                }

            })
    });

    useEffect(() => {
        if (signed && account)
            callAPI({}, 'collectionInfo').then(resp => {
                setCollectionInfo(resp.payload.collection);
                startSale(new Date(resp.payload.collection.sale.sale_dt) <= new Date())
            })
    }, [account, signed, refresh])

    return (
        <>
            <Container fluid className={'project-banner d-flex flex-column align-items-center justify-content-center'}
                       /*style={{
                           backgroundImage: `url(${madzBg})`
                       }}*/>
                {/*<Row className={'launch-type mt-auto mx-auto p-2'}>*/}
                {/*    <Col xs={12}>*/}
                {/*        {type}*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className={'text-center mb-auto mx-auto text-light'}>
                    <Col xs={12} className={'mx-auto px-0 mt-2 py-2 saleDateDiv'}>
                        {soldOut ? (
                            <div className={'mx-0 px-2'}>
                                SOLD OUT!
                            </div>
                        ) : saleStarted ? (
                            <div className={'mx-0 px-2'}>
                                <img style={{top: '-2px', position: 'relative'}} src={greenCircle} height={10}
                                     className={'me-2 animate-flicker'}/>SALE IS LIVE</div>
                        ) : collectionInfo?.sale.sale_dt ? (
                            <div className={'mx-0 px-0 mt-1'}>
                                <div className={'mx-auto mt-0'}>{saleDateStr(collectionInfo?.sale.sale_dt)}</div>
                            </div>
                        ) : null
                        }
                    </Col>
                </Row>
                {/*<Row className={'my-auto mx-auto p-2'}>*/}
                {/*    <Col xs={12}>*/}
                {/*        <div className={'mx-1 px-3 pt-1 d-flex align-items-center'}>{saleStarted &&*/}
                {/*            <img style={{top: '-2px', position: 'relative'}} src={greenCircle} height={15}*/}
                {/*                 className={'me-2'}/>}{saleStarted ? `SALE IS LIVE` : saleDateStr()}</div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Container>
            <Container>
                <Row>
                    <Col className={'text-center'}> <img src={madzLogo} className={'img-fluid'}/></Col>
                </Row>
                <Row>
                    <Col className={'text-center d-flex underTitleFeatures justify-content-center'}>
                        <div className={'mx-1 px-3 pt-1'}>INO</div>

                        <div className={'mx-1 px-3 d-flex align-items-center'}><img src={ethNetworkImg}
                                                                                    className={'img-fluid'}/></div>
                        <div className={'mx-1 px-3 text-center d-flex align-items-center'}><a
                            href={'https://twitter.com/MadzNFT'}
                            target={'_blank'}><Twitter size={18}
                                                       color={'#03E500'}/></a>
                        </div>
                        {/*<div className={'mx-1 px-3 text-center d-flex align-items-center'}><a*/}
                        {/*    href={'https://madz.wtf'}*/}
                        {/*    target={'_blank'}><Globe size={18}*/}
                        {/*                             color={'#03E500'}/></a>*/}
                        {/*</div>*/}
                    </Col>
                </Row>

                <Row>
                    <Col md={8} className={'text-center my-4 mx-auto project-description'}>
                        <p className={'sectionParagraph'}>
                            Created by artists, driven by community, and powered by utility. Join the select few in the NFT collection that's poised to revolutionize the web3 community.
                        </p>
                    </Col>
                </Row>
                {saleStarted && !soldOut &&
                    <Row>
                        <ParticipationBox account={account} signed={signed} saleInfo={collectionInfo?.sale}
                                          userInfo={collectionInfo?.user} refresh={() => incRefresh(refresh + 1)}/>
                    </Row>}
                <Row className={'project-features my-md-5 mt-3'}>
                    {projectFeatures.map((e) => {
                        return (
                            <Col md={4}>
                                <Row className={'mx-2'}>
                                    <Col
                                        className={'one-feature mb-2 mb-mb-0 pt-4 pb-2 d-flex flex-column align-items-center justify-content-center'}>
                                        <h2 className={'text-center'}>{e.title}</h2>
                                        <h5 className={'text-center'}>{e.value}</h5>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
                <Row className={'my-md-5 my-2'}>
                    <Col xs={12} className={'mt-md-5 mb-3'}>
                        <h1 className={'sectionTitle text-center'}>MADZ <img src={collabThunder} height={70}/> BULLRUN
                            COLLAB</h1>
                        <h3 className={'sectionSubTitle text-center text-primary mt-5'}>BULLRUN STAKING BOOSTER</h3>
                    </Col>
                    <Col md={3} className={'mb-3 mb-md-0'}>
                        <img src={stakingBoosterImg} className={'img-fluid'} style={{border: 'solid 1px green'}}/>
                    </Col>
                    <Col md={9}>
                        <p className={'sectionParagraph'} style={{
                            lineHeight: '2.5rem'
                        }}>

                                By Staking only your BRL Tokens without the <span className={'text-primary'}>BULLRUN BOOSTER NFT</span>, you are limited to only 70%
                                Of <span className={'text-primary'}>USDC</span> distributions. However, with every additional <span className={'text-primary'}>BULLRUN BOOSTER NFT</span>, you recieve 6% on
                                each <span className={'text-primary'}>BOOSTER NFT</span> For a maximum of <span className={'text-primary'}>FIVE BOOSTERS</span> In total.
                                <br/>
                                By Staking Five <span className={'text-primary'}>BOOSTER NFTs</span>, You unlock 100% of your <span className={'text-primary'}>USDC</span> distributions. The <span className={'text-primary'}>BOOSTERS</span> also
                                serve as a scalable source of revenue, which are used to feed into the liquidity pool. This
                                enables <span className={'text-primary'}>BULLRUN</span> to build and sustain a healthy liquidity. Volume dictates the <span className={'text-primary'}>USDC</span> distribution amounts.
                                <br/>
                                As long as there are ongoing trades on Uniswap, the USDC distributions will be perpetually
                                accumulated by the Staking participants.

                        </p>
                        {/*<div className={'collabTerms mt-5'}>*/}
                        {/*    <ul>*/}
                        {/*        {collabTerms.map((t) => {*/}
                        {/*            return (*/}
                        {/*                <li className={'mb-3'}>*/}
                        {/*                    <img src={greenStar} height={25}/> {t.term}*/}
                        {/*                </li>*/}
                        {/*            )*/}
                        {/*        })}*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </Col>
                </Row>
                {/*<Row className={'my-md-5'}>*/}
                {/*    <Col xs={12} className={'mt-md-5 my-2'}>*/}
                {/*        <h1 className={'sectionTitle text-center mt-5 mb-0'}>ELIGIBILITY REQUIREMENTS</h1>*/}
                {/*        <h6 className={'sectionSubTitle text-center mb-5'}>NFTS ARE GUARANTEED ON FCFS BASIS</h6>*/}
                {/*    </Col>*/}
                {/*    <Col xs={12} className={'mb-5'}>*/}
                {/*        <Row>*/}
                {/*            {eligibilities.map((e) => {*/}
                {/*                return (*/}
                {/*                    <Col xs={12} className={'d-flex flex-column eligibility px-1 mb-3'}>*/}
                {/*                        <span className={'rule text-center mb-2'}>{e.rule}</span>*/}
                {/*                        <img src={circleThunder} width={46} className={'mx-auto'}/>*/}
                {/*                        <span className={'outcome text-center mt-2'}>{e.outcome}</span>*/}
                {/*                    </Col>*/}
                {/*                )*/}
                {/*            })}*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row className={'my-md-5'}>*/}
                {/*    <Col xs={12} className={'mt-md-5 my-2'}>*/}
                {/*        <h1 className={'sectionTitle text-center'}>THE SIX MAD RINDZ</h1>*/}
                {/*        <p className={'sectionParagraph'}>*/}
                {/*            As the first NFT Project to adopt a rinds-based system, MADZ NFTs are grouped into six Rinds*/}
                {/*            that represent madness in varied ways: MISFITZ, CRYPTOMANIACZ, GAMERZ, WEEKENDERZ, CREATORZ,*/}
                {/*            JOKESTERZ.*/}
                {/*        </p>*/}
                {/*    </Col>*/}
                {/*    {helmets.map((h) => {*/}
                {/*        return (*/}
                {/*            <Col lg={2} md={4} xs={6} className={'p-0 text-center helmetDiv'}><img src={h.img}*/}
                {/*                                                                                   className={'img-responsive helmet'}/></Col>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</Row>*/}
                {/*<Row className={'my-md-5'}>*/}
                {/*    <Col xs={12} className={'my-md-5 my-2'}>*/}
                {/*        <h1 className={'sectionTitle text-center'}>MAD PASS</h1>*/}
                {/*        <p className={'sectionParagraph'}>*/}
                {/*            Holders must first obtain their MADPASS NFTs to redeem their Avatars. The MADPASS is*/}
                {/*            represented through six different helmets for the six different Rinds. The helmets will*/}
                {/*            teleport the holders' madness into the metaverse thus bringing their avatars to life.*/}
                {/*        </p>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row className={'my-md-5'}>*/}
                {/*    <Col xs={12} className={'my-md-5 my-2'}>*/}
                {/*        <h1 className={'sectionTitle text-center'}>TRAILER</h1>*/}
                {/*        <div className="teaser-container mx-auto">*/}
                {/*            <div className="video-toggle" onClick={() => {*/}
                {/*                videoRef.current.play();*/}
                {/*                document.getElementsByClassName('video-toggle')[0].style.display = 'none';*/}
                {/*            }}>*/}
                {/*                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"*/}
                {/*                     fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"*/}
                {/*                     className="feather feather-play active">*/}
                {/*                    <polygon points="5 3 19 12 5 21 5 3"></polygon>*/}
                {/*                </svg>*/}
                {/*            </div>*/}
                {/*            <video onClick={() => {*/}
                {/*                videoRef.current.pause();*/}
                {/*                document.getElementsByClassName('video-toggle')[0].style.display = 'block';*/}
                {/*            }} ref={videoRef} width={'100%'} src={"https://cdn.madz.wtf/videos/madz_trailer.mp4"}*/}
                {/*                   poster={"https://cdn.madz.wtf/videos/madz_trailer.png"}*/}
                {/*                   loop={false} preload={"metadata"}*/}
                {/*            ></video>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row className={'my-md-5'}>*/}
                {/*    <Col xs={12} className={'mt-md-5 mt-2'}>*/}
                {/*        <h1 className={'sectionTitle text-center'}>TEAM</h1>*/}
                {/*    </Col>*/}
                {/*    <Col xs={10} className={'mx-auto mb-md-5 mb-2'}>*/}
                {/*        <Row>*/}
                {/*            {team.map((m) => {*/}
                {/*                return (*/}
                {/*                    <Col md={2} className={'oneTeam text-center my-2'}>*/}
                {/*                        <img src={m.img} className={'img-fluid'}/>*/}
                {/*                        <h3 className={'mt-2 mb-0'}>{m.name}</h3>*/}
                {/*                        <h5 dangerouslySetInnerHTML={{__html: m.title}}></h5>*/}
                {/*                    </Col>*/}
                {/*                )*/}
                {/*            })}*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Container>
            <Container fluid className={'fixed-bottom'}>
                <Marquee className={'bullRunmarquee py-2'}>
                    BULLRUNNER and MADZ Participants will be rewarded with <span className={'text-primary'}>Staking Booster</span> NFTs
                    that once staked with BRL will maximize $USDC staking distributions. <img src={starSep}
                                                                                               className={'mx-2'}/>
                </Marquee>
            </Container>
        </>
    )
}

export default Madz;
