import {WalletConnectConnector} from '@web3-react/walletconnect-connector';
import {InjectedConnector} from "@web3-react/injected-connector";
import DappConfig from './dapp.js';
import {CoinbaseWalletSDK} from "@coinbase/wallet-sdk";
import {WalletLinkConnector} from "@web3-react/walletlink-connector";

const POLLING_INTERVAL = DappConfig.pollingInterval;
const RPC_URLS = DappConfig.readOnlyUrls[DappConfig.readOnlyChainId];

const injected = new InjectedConnector({});

const walletconnect = new WalletConnectConnector({
    rpc: {[DappConfig.readOnlyChainId]: RPC_URLS},
    bridge: 'https://bridge.walletconnect.org',
    infuraId: process.env.REACT_APP_INFURA_KEY,
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
    supportedChainIds: [DappConfig.readOnlyChainId],
    chainId: DappConfig.readOnlyChainId,
});

const Coinbase = new WalletLinkConnector({
    url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
    appName: "BullRun",
    appLogoUrl: 'https://bullrun.life/bullrun_share.jpg',
    darkMode: true,
    supportedChainIds: [DappConfig.readOnlyChainId],
});


export default {
    injected,
    walletconnect,
    Coinbase
}