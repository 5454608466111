import React from "react";
import Countdown from "react-countdown";
import greenCircle from "../../assets/images/launchpad/green_circle.png";

function CountdownTimer({date, small}) {
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        return completed ? (
            <div className={'mx-0 px-2'}><b>SALE IS OVER!</b></div>
        ) : (
            !isNaN(date) ? (
                <div
                    className={`counterContainer d-flex justify-content-center align-items-center space-x-5 txt_${
                        small ? "sm" : "lg"
                    } _bold`}
                >
                    {(days && +days > 0) ? (
                        <div className="item">
                            <div className="number days text_bold">
                                {days}
                                <span>D</span>
                            </div>
                        </div>
                    ) : null}
                    {(days > 0) && <div className="dots"></div>}
                    <div className="item">
                        <div className="number hours text_bold">
                            {hours}
                            <span>H</span>
                        </div>
                    </div>
                    <div className="dots"></div>
                    <div className="item">
                        <div className="number minutes text_bold">
                            {minutes}
                            <span>M</span>
                        </div>
                    </div>
                    <div className="dots"></div>
                    <div className="item">
                        {" "}
                        <div className="number seconds text_bold">
                            {seconds}
                            <span>S</span>
                        </div>
                        {" "}
                    </div>
                </div>
            ) : (
                "-- : -- : -- : --"
            )
        );
    };
    return <Countdown date={date} renderer={renderer} zeroPadTime={2}/>;
}

export default CountdownTimer;
