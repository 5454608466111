import {Col, Container, Row} from "react-bootstrap";
import Header from "../../sections/Header";
import ScrollToTop from "../../components/ScrollToTop";

import whiteArrowsBg from "../../assets/images/launchpad/white_arrows_bg.png";
import Madz from "./Madz";
import OneProject from "./OneProject"

import "./style.scss";
import Footer from "../../sections/Footer";
import Disclaimer from "../../sections/Disclaimer";
import {useEffect, useState} from "react";
import callAPI from "../../api";
import {useParams} from "react-router-dom";

function NFTLaunchpad({account, signed}) {

    let {projectSlug} = useParams();

    const [isLoading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);

    const [curProjectType, setProjectType] = useState('LIVE');
    const [requested, setRequested] = useState(false);

    const PROJECT_TYPES = [
        {
            label: 'Live',
            value: 'LIVE'
        },
        {
            label: 'Upcoming',
            value: 'UPCOMING'
        },
        {
            label: 'Completed',
            value: 'COMPLETED'
        }
    ]

    const getProjects = (newType) => {
        setLoading(true)
        callAPI({type: newType}, 'collections').then(resp => {
            if (resp.status == 'SUCCESS')
                setProjects(resp.payload.projects);

            setLoading(false);
        })
    }

    const getProjectsByType = (newType) => {
        if (!isLoading) {
            setProjects([]);
            setProjectType(newType);
            getProjects(newType);
        }
    }

    useEffect(() => {
        if (projects.length == 0 && !requested) {
            setRequested(true)
            getProjects(curProjectType);
        }
    }, [projects]);

    return (
        <Container fluid className={'align-content-center page-container px-0'} style={{
            backgroundColor: '#000',
            minHeight: '100vh'
        }}>
            <Header account={account}/>
            <ScrollToTop/>


            {projectSlug ? (
                <>
                    <Madz type={'COMPLETED'} account={account} signed={signed}/>
                    <Container>
                        <Disclaimer
                            text={`The only function of the BULLRUN token, or BULLRUN NFTs, is to <span class='text-primary'>grant holders access to all future tokens and NFT collections. </span> Holding BRL should not be considered an investment. No statements made in this document, nor any BULLRUN communication channels, nor by any BULLRUN “community members” or holders, should be considered financial advice. BRL holders should not expect any “return on investment”, nor that the “BULLRUN team” will work to provide value to their holdings.`}/>
                    </Container>
                </>
            ) : (
                <Container>
                    <Container style={{marginTop: 90}}>
                        <div id={"projectTypes"} className={'d-flex d-flex-row'}>
                            {PROJECT_TYPES.map((t) => {

                                let cls = 'projectType';
                                if (t.value == curProjectType)
                                    cls += ' active'

                                return <div className={cls} onClick={() => {
                                    getProjectsByType(t.value);
                                }}>{t.label}</div>
                            })}
                        </div>
                    </Container>
                    <Container>
                        {projects.length == 0 &&
                            <div id={"noProjects"}
                                 className={'mt-5 d-flex flex-column justify-content-center align-items-center text-center'}
                                 style={{backgroundImage: !isLoading ? `url(${whiteArrowsBg})` : null}}>
                                {isLoading && <div class="spinner-border spinner-border-lg text-light"><span
                                    class="visually-hidden">Loading...</span></div>}
                                {!isLoading && <>
                                    <p>We do not have any IDO or INO offerings with this status available at the
                                        moment.</p>
                                    <p>Please stay tuned for updates on our projects.</p>
                                </>}
                            </div>
                        }
                    </Container>
                    <Row className={'mt-5'}>
                        {projects.map((project) => {
                            return <Col lg={4} md={6}>
                                <OneProject project={project}/>
                            </Col>;
                        })}
                    </Row>
                </Container>
            )}


            <Footer/>
            <Container>
                <Row>
                    <Col className={'mb-1'}></Col>
                </Row>
            </Container>
        </Container>
    )
}

export default NFTLaunchpad;
