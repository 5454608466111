import React from 'react';
import './style.scss'
import Modal from "../modal";
import {useSystem} from "../../../hooks/use-system";
import {useEthers} from "@usedapp/core";


function ConnectingWallet({...props}) {

    const system = useSystem();
    const {account} = useEthers();

    if (account) {
        system.showModal('');
    }

    return (
        <Modal {...props} title="Connecting To...">
            <div className={'text-center connectingWalletLabel'}>
                {props.wallet} <img src={props.icon}/>
            </div>
        </Modal>
    );
}

export default ConnectingWallet;
