import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/security-bg.jpg";

function Security() {

    return (
        <Container fluid className={'section-continuous d-flex justify-content-center align-items-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            position: 'relative'
        }}>
                <Row className={'ps-4'}>
                    <Col md={4} className={'sectionText'}>
                        <div className={'sectionText mt-2'}>
                            <h2 className={'text-primary text-start'}>BULLRUN</h2>
                            <h3 className={'text-white text-start'}>SECURITY</h3>
                        </div>
                        <p className={'moreNormal withShadow mt-5'}>
                            The crypto industry is still widely
                            unregulated and thus, littered with
                            bad actors.
                        </p>
                        <p className={'moreNormal withShadow mt-2'}>
                            Keeping in mind the fear of the rampant scams and rug pulls experienced in the industry, our solution is to develop and launch our own in-house community tokens.
                        </p>
                        <p className={'moreNormal withShadow mt-2 text-primary'}>
                            Two ways to succeed in this industry, are to get involved with a vibrant and active
                            community and get in early on the token launches.
                        </p>
                        <p className={'moreNormal withShadow mt-2'}>
                            {/*That is exactly what we offer our*/}
                            {/*community members.*/}
                            <h3 className={'text-white text-start'}>success is all we know!</h3>
                        </p>
                    </Col>
                    <Col md={8}></Col>
                </Row>
        </Container>
    )
}

export default Security;
