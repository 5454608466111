import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/disclaimer-bg.jpg";
import brl from "../../assets/images/new-design/rotating-coin.gif";
import {Globe, Telegram, Twitter, Youtube} from "react-bootstrap-icons";
import surv from "../../assets/images/3rd-design/surv.gif";
import wha from "../../assets/images/3rd-design/wha.gif";


function Disclaimer({text}) {

    return (
        <Container fluid className={'section-continuous d-flex align-items-center justify-content-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: 'rgba(0,0,0, 0.2)'
        }}>
            <Container>
                <Row>
                    <Col className={'mx-md-5 disclaimer py-5 px-md-5 px-3'}>
                        <h1 className={'text-start mb-5'}>Disclaimer</h1>
                        <p dangerouslySetInnerHTML={{__html: text}}></p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Disclaimer;
