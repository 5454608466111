import "./style.scss";
import {Globe, Twitter} from "react-bootstrap-icons";
import ethNetworkImg from "../../../assets/images/networks/ethereum-logo.svg";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";



function OneProject({project}) {
    let navigate = useNavigate();
    return (
        <div className={'oneProject p-3 my-2'}>
            <div className={'banner mb-3'}>
                <img src={project.banner_url} className={'img-fluid'}/>
            </div>
            <div className={'nameAndContact mt-2 d-flex flex-row justify-content-between align-items-center'}>
                <div className={'d-flex'}>
                    <h5 className={'me-3 mb-0'}>{project.name}</h5>
                    <span>{project.offering_type}</span>
                    <img src={ethNetworkImg} className={'mx-2'} height={15} title={'Ethereum Mainnet'} alt={'Ethereum Mainnet'}/>
                    <a href={project.twitter} target={'_blank'} className={'mx-2'}><Twitter size={15} color={'#03E500'}/></a>
                    {/*<a href={project.website} target={'_blank'} className={'mx-2'}><Globe size={15} color={'#03E500'}/></a>*/}
                </div>
                <div className={'projectDate'}>{project.date}</div>
            </div>
            <div className={'description my-2'}>
                {project.description}
            </div>
            <div className={'saleInfo mt-4'}>
                <div className={'oneSaleInfo'}>
                    <h5>TOTAL SUPPLY</h5>
                    <span>{project.total_supply}</span>
                </div>
                {/*<div className={'oneSaleInfo'}>*/}
                {/*    <h5>TOTAL RAISED</h5>*/}
                {/*    <span>{project.total_raised} ETH</span>*/}
                {/*</div>*/}
                {/*<div className={'oneSaleInfo'}>*/}
                {/*    <h5>SALE PRICE</h5>*/}
                {/*    <span>{project.sale_price} ETH</span>*/}
                {/*</div>*/}
            </div>
            <div className={'text-center my-2'}>
                <Button className={'detailsBtn'} onClick={(e) => {
                    navigate(`/launchpad/${project.slug}`)
                }}>View Details</Button>
            </div>
        </div>
    )
}

export default OneProject;