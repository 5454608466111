import React, {useEffect, useState} from 'react';
import './style.scss'
import Modal from "../modal";
import {Button, Col, Container, InputGroup, Row, Form, Tabs, Tab} from "react-bootstrap";

import calculatorImg from "../../../assets/images/new-design/calculator.png";
import bullUp from "../../../assets/images/bull-up-light.png";
import {BrlTotalSupply} from "../../../hooks/use-dapp";
import {prettyNumber} from "../../../utils/string";
import web3 from "web3";


function Calculator({...props}) {

    const [stakedAmount, setStakedAmount] = useState(null);
    const [nfts, setNFTs] = useState(null);
    const [result, setResult] = useState(null);

    const calculate = () => {
        const supply = web3.utils.fromWei( props.brlTotalSupply, 'ether');
        var totalStaked = (stakedAmount / parseFloat(supply));
        var totalVolume = (nfts * 0.02);
        var calculate = (totalStaked * totalVolume);

        setResult(calculate.toFixed(4))
    }

    useEffect(() => {
        setResult(null)
    }, [stakedAmount, nfts])

    return (
        <Modal {...props} title="BRL STAKING CALCULATOR">
            <div className={'text-center calculator'}>
                <Container>
                    <Row>
                        <Col md={6} className={'d-flex'}>
                            <Row>
                                <Col md={12} className={'d-flex flex-column text-start'}>

                                    Staked Amount
                                    <Form.Control
                                        placeholder={"0.00"}
                                        value={stakedAmount}
                                        aria-label="amount"
                                        type={'number'}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => {
                                            setStakedAmount(e.target.value);
                                        }}
                                    />

                                </Col>
                                <Col md={12} className={'d-flex flex-column text-start'}>
                                    Staked NFTs
                                    <Form.Control
                                        placeholder={"0"}
                                        value={nfts}
                                        aria-label="amount"
                                        type={'number'}
                                        aria-valuemax={5}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => {
                                            setNFTs(e.target.value <=5 ? e.target.value : 0);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <img src={calculatorImg} className={'img-fluid'}/>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col md={8} className={'text-center'}>
                            <h5 className={'text-primary'}>DAILY REWARDS</h5>
                        </Col>
                        <Col md={4} className={'text-center'}>
                            <h5 className={'text-primary'}>{result ? result : 'N/A'}</h5>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col md={3} className={'d-flex'}>

                        </Col>
                        <Col md={6}>
                            <Button className={'brandButton ms-lg-2 mb-2'} onClick={(e) => {
                                calculate()
                            }}>
                                CALCULATE <img src={bullUp} alt={'BullRun Up Arrow'}/>
                            </Button>
                        </Col>
                        <Col md={3} className={'d-flex flex-column justify-content-around'}>

                        </Col>
                    </Row>
                </Container>

            </div>
        </Modal>
    );
}

export default Calculator;
