import './style.scss'
import {Col, Container, Row} from "react-bootstrap";
import coinFlame from "../../assets/images/new-design/coin-flame.gif";


function LiquidityChallenge() {

    return (
        <Container className={'section-continuous'}>
            <Row>
                <Col md={12}>
                    <h2 className={'text-primary text-center'}>BULLRUN SOLVES LIQUIDITY CHALLENGE</h2>
                </Col>
            </Row>
            <Row>
                <Col md={8} className={'sectionText mt-5'}>
                    <div className={'liquidityChallenge p-4'}>
                        <p>
                            After a thorough study and deep research of the last bull run, we identified major
                            challenges as
                            lack of liquidity and token inflation, in several crypto projects.We then decided to step
                            forward and use the wealth of knowledge we gathered during our research and studies of the
                            DeFi
                            space and created BULLRUN.
                        </p>
                        <p>

                            BULLRUN is community driven, our main purpose and focus is to solve the liquidity and token
                            inflation challanges, and to also unite all crypto enthusiasts who yearn for the upcoming
                            bull
                            run. The vast majority of the projects do not have a long-term vision, and most do not have
                            the
                            best interest of the community at heart.
                        </p>
                        <p>

                            BULLRUN was created in a harsh bear market strategicaly, that is the battle ground where
                            strong
                            fundamentals are tested and stand the trial. To date, our ecosystem has proven to be highly
                            effective in such conditions. Our goal and mission is to create a sustainable, deflationary
                            and
                            secure ecosystem, with a deep and healthy liquidity. To achieve this, we implemented the
                            bellow
                            strategies.
                        </p>
                        <p>
                            BULLRUN is community driven and it focuses on developing sustainable and hyper-deflationary
                            tokens. The BULLRUN ecosystem’s main purpose is to solve liquidity and volume challenges. It
                            does that by implementing.
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <img src={coinFlame} className={'img-fluid'}/>
                </Col>
            </Row>
        </Container>
    )
}

export default LiquidityChallenge;