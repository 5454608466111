import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

import bg from "../../assets/images/3rd-design/social-bg.jpg";
import t from "../../assets/images/3rd-design/t.png";
import x from "../../assets/images/3rd-design/x.png";
import y from "../../assets/images/3rd-design/y.png";

function Social() {


    const socials = [
        {
            label: 'Twitter',
            link: 'https://twitter.com/BullRunLife',
            img: x,
        },
        {
            label: 'Telegram',
            link: 'https://t.me/BullRunLife',
            img: t
        },
        {
            label: 'Youtube',
            link: 'https://youtube.com/c/BeCreative2',
            img: y
        }
    ]

    return (
        <Container fluid className={'section-continuous d-flex justify-content-center  align-items-center'} style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60% auto',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            position: 'relative'
        }}>
                <Row>
                    <Col md={8} ></Col>
                    <Col md={4}  className={'sectionText'}>
                        <h2 className={'text-primary text-start'}>BULLRUN</h2>
                        <h3 className={'text-white text-start'}>SOCIAL NETWORK</h3>
                        <p className={'moreNormal text-primary withShadow mt-3'}>
                            We freely give out our personal data to large centralized institutions and social media platforms, which in turn monetize that in their best interest.
                            We are strategically targeted by customized ads that are eager to relieve us of our hard earned income.
                        </p>
                        <p className={'moreNormal withShadow mt-3'}>
                            Whether you are a content creator, a community leader, a social media influencer, or a positive individual who enjoys sharing and educating on their experiences, join the movement.
                        </p>
                        <p><h3 className={'text-white text-start mx-0 px-0'} style={{fontSize: '22px'}}>the global decentralized community</h3></p>
                        <Row>
                            {socials.map( (s) => {
                                return <Col md={4}><a key={`contactSocial${s.label}`} className={'mb-3'} href={s.link}  rel="noreferrer" target={'_blank'}><img src={s.img} width={100}/> </a></Col>
                            })}
                        </Row>
                    </Col>
                </Row>
        </Container>
    )
}

export default Social;
